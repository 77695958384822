import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import AddEditMenu from './AddEditMenu';
import Menus from './Menus';

const MenusWrapper = () => {
  return (
    <Switch>
      <Route exact path={ROUTES?.MENUS} component={Menus} />
      <Route exact path={`${ROUTES?.MENUS}/add`} component={AddEditMenu} />
      <Route
        exact
        path={`${ROUTES?.MENUS}/:menuId/edit`}
        component={AddEditMenu}
      />
      <Route
        exact
        path={`${ROUTES?.MENUS}/:menuId`}
        render={() => <Redirect to={ROUTES?.MENUS} />}
      />
    </Switch>
  );
};

export default MenusWrapper;
