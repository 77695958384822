import React from 'react';

function TagHero({
  background = 'bg-moss-2',
  title,
  textColor = 'text-n-900',
  responsive
}) {
  return (
    <section
      className={`taghero ${background} ${responsive ? 'responsive-view' : ''}`}
    >
      <div className={`title ${textColor}`}>{title}</div>{' '}
    </section>
  );
}
export default TagHero;
