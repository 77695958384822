import React from 'react';
import CtaCard from '../../ctaModule/CtaCard';

function CtaMarketingBlock({
  contentlight,
  background,
  img,
  title,
  date,
  location,
  secondary,
  primary,
  description,
  responsive
}) {
  return (
    <CtaCard
      responsive={responsive}
      contentlight={contentlight}
      ctaMarketing
      background={background}
      img={img}
      title={title}
      date={date}
      location={location}
      secondary={secondary}
      primary={primary}
      description={description}
    />
  );
}
export default CtaMarketingBlock;
