import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Error404 from '../Error404';
import { ROUTES } from '../common/constants';
import AudioWrapper from '../modules/assets/audio';
import DocumentWrapper from '../modules/assets/document';
import IconsWrapper from '../modules/assets/icons';
import ImagesWrapper from '../modules/assets/images';
import TextWrapper from '../modules/assets/text';
import VideosWrapper from '../modules/assets/videos';
import AuthorWrapper from '../modules/labels/author';
import CollectionsWrapper from '../modules/labels/collections';
import TagsWrapper from '../modules/labels/tags';
import TopicsWrapper from '../modules/labels/topics';
import MenusWrapper from '../modules/menus';
import PagesWrapper from '../modules/pages';
import ProfileWrapper from '../modules/profile';
import UILabelsWrapper from '../modules/ui-labels';
import VideosModuleWrapper from '../modules/videos';

const ContentRoutes = () => (
  <>
    <Switch>
      <Route path={ROUTES?.VIDEOS} component={VideosWrapper} />
      <Route path={ROUTES?.ICONS} component={IconsWrapper} />
      <Route path={ROUTES?.MENUS} component={MenusWrapper} />
      <Route path={ROUTES?.DOCUMENT} component={DocumentWrapper} />
      <Route path={ROUTES?.AUDIO} component={AudioWrapper} />
      <Route path={ROUTES?.TEXT} component={TextWrapper} />
      <Route path={ROUTES?.AUTHOR} component={AuthorWrapper} />
      <Route path={ROUTES?.TOPICS} component={TopicsWrapper} />
      <Route path={ROUTES?.TAGS} component={TagsWrapper} />
      <Route path={ROUTES?.COLLECTIONS} component={CollectionsWrapper} />
      <Route path={ROUTES?.IMAGES} component={ImagesWrapper} />
      <Route path={ROUTES?.VIDEOS_MODULE} component={VideosModuleWrapper} />
      <Route path={ROUTES?.PAGES_MODULE} component={PagesWrapper} />
      <Route path={ROUTES?.UI_LABELS} component={UILabelsWrapper} />
      <Route path={ROUTES?.PROFILE} component={ProfileWrapper} />

      <Route
        exact
        path={ROUTES?.MAIN}
        render={() => <Redirect to={ROUTES?.IMAGES} />}
      />
      <Route path="*" exact component={Error404} />
    </Switch>
  </>
);

export default ContentRoutes;
