import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../../common/constants';
import AddEditTopic from './AddEditTopic';

import Topics from './Topics';

const TopicsWrapper = () => {
  return (
    <Switch>
      <Route exact path={ROUTES?.TOPICS} component={Topics} />
      <Route exact path={`${ROUTES?.TOPICS}/add`} component={AddEditTopic} />
      <Route
        exact
        path={`${ROUTES?.TOPICS}/:topicId/edit`}
        component={AddEditTopic}
      />
      <Route
        exact
        path={`${ROUTES?.TOPICS}/:topicId`}
        render={() => <Redirect to={ROUTES?.TOPICS} />}
      />
    </Switch>
  );
};

export default TopicsWrapper;
