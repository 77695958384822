import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../../common/constants';
import AddEditAudio from './AddEditAudio';
import Audio from './Audio';

const AudioWrapper = () => (
  <Switch>
    <Route path={ROUTES?.AUDIO} exact component={Audio} />
    <Route path={`${ROUTES?.AUDIO}/add`} component={AddEditAudio} />
    <Route path={`${ROUTES?.AUDIO}/:id/edit`} component={AddEditAudio} />
    <Route
      exact
      path={`${ROUTES?.AUDIO}/:id`}
      render={() => <Redirect to={ROUTES?.AUDIO} />}
    />
  </Switch>
);

export default AudioWrapper;
