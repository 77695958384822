import { Button, ConfigProvider, Typography } from 'antd';
import React from 'react';
import Image from '../../../components/Image';
import WebStyle from '../../../styles/style-web.json';

const { Title } = Typography;
function CtaCard({
  date,
  location,
  ctaMarketing = false,
  primary,
  secondary,
  contentlight,
  title,
  description,
  img,
  blurHash,
  background = 'bg-blue',
  responsive
}) {
  return (
    <ConfigProvider theme={WebStyle}>
      <section>
        <div className="container-rnp">
          <div
            className={`ctacard  ${background} ${
              contentlight ? 'text-n-100' : ''
            } ${ctaMarketing && `ctaMarketing`} ${
              responsive ? 'responsive-view' : ''
            }`}
          >
            {img ? (
              <div className="ctaCard-img relative">
                <Image blurHash={blurHash} src={img} alt={title} />
              </div>
            ) : null}

            <div className="ctacard-dec ">
              {title ? (
                <Title className={`${contentlight && 'text-n-0'}`} level={3}>
                  {title}
                </Title>
              ) : null}

              {date && (
                <div className={`date ${contentlight && 'text-n-0'}`}>
                  {date}
                </div>
              )}
              {location && (
                <div className={`date ${contentlight && 'text-n-0'}`}>
                  {location}
                </div>
              )}

              {description ? (
                <p className={`paragraph ${contentlight && 'text-n-0'}`}>
                  {description}
                </p>
              ) : null}

              <div className="cta-button-wrapper">
                {secondary ? (
                  <Button
                    className={`button-l cs-button b-1 b-solid bg-transparent ${
                      contentlight
                        ? 'text-n-200 b-n-200 hbg-n-900 hc-n-100 hb-n-900'
                        : 'text-n-900  hbg-n-900 hc-n-100 hb-n-900   b-n-900 '
                    }`}
                    size="large"
                  >
                    {secondary}
                  </Button>
                ) : null}
                {primary ? (
                  <Button
                    className={`button-l  cs-button   ${
                      // eslint-disable-next-line no-nested-ternary
                      contentlight
                        ? ctaMarketing
                          ? 'bg-n-100 b-n-100 hbg-transparent hc-n-100 hb-n-100'
                          : 'bg-n-200 text-n-900 hbg-n-900 hc-n-100 hb-n-900'
                        : 'text-n-100 bg-n-900 hbg-n-100 hc-n-900 hb-n-100'
                    }`}
                    size="large"
                  >
                    {primary}
                  </Button>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </section>
    </ConfigProvider>
  );
}
export default CtaCard;
