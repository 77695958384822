import { Carousel } from 'antd';
import React from 'react';
import SectionHeader from '../SectionHeader/SectionHeader';
import CategoryCard from './CategoryCard';

function CategoryCarousel({
  sectionHeading,
  headerLink,
  headerDescription,
  responsive,
  data = []
}) {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToScroll: 1,
    variableWidth: true,
    draggable: true,
    loop: true,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <section
      className={`category-slider ${responsive ? 'responsive-view' : ''}`}
    >
      <div className="container">
        <SectionHeader
          heading={sectionHeading}
          headerLink={headerLink}
          headerDescription={headerDescription}
        />
      </div>
      <div className="one-side-carousel shadow-arrow">
        <Carousel {...settings}>
          {data?.map((item) => {
            return (
              <div key={item?.id}>
                <CategoryCard
                  href={item?.href}
                  topic={item?.topic}
                  backgroundColor={item?.backgroundColor}
                  textColor={item?.textColor}
                />
              </div>
            );
          })}
        </Carousel>
      </div>
    </section>
  );
}
export default CategoryCarousel;
