import { Carousel } from 'antd';
import React from 'react';
import SectionHeader from '../SectionHeader/SectionHeader';
import TagCard from './TagCard';

function TagCarousel({ className, icon, responsive, headerLink, data = [] }) {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 2,
    variableWidth: true,
    draggable: true,
    loop: true,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <div className={`tag_carousel ${responsive ? 'responsive-view' : ''}`}>
      <SectionHeader headerLink={headerLink} />
      <Carousel {...settings}>
        {data?.map((item) => {
          return (
            <div key={item.id}>
              <TagCard
                className={className}
                icon={icon}
                name={item?.name}
                href={item?.href}
              />
            </div>
          );
        })}
      </Carousel>
    </div>
  );
}
export default TagCarousel;
