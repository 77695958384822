import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../../common/constants';
import AddEditDocument from './AddEditDocument';
import Document from './Document';

const DocumentWrapper = () => (
  <Switch>
    <Route path={ROUTES?.DOCUMENT} exact component={Document} />
    <Route path={`${ROUTES?.DOCUMENT}/add`} component={AddEditDocument} />
    <Route path={`${ROUTES?.DOCUMENT}/:id/edit`} component={AddEditDocument} />
    <Route
      exact
      path={`${ROUTES?.DOCUMENT}/:id`}
      render={() => <Redirect to={ROUTES?.DOCUMENT} />}
    />
  </Switch>
);

export default DocumentWrapper;
