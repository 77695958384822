import { Modal } from 'antd';
import React from 'react';
import ReactPlayer from 'react-player/lazy';
import { ASSET_CATEGORY } from '../common/constants';
import Image from './Image';

const PreviewModal = ({
  show,
  url,
  type = ASSET_CATEGORY.IMAGE,
  title,
  onClose
}) => {
  return (
    <Modal
      wrapClassName="preview-modal"
      width={900}
      destroyOnClose
      centered
      open={show}
      title={title}
      footer={null}
      onCancel={onClose}
    >
      <div className="center-media">
        {type === ASSET_CATEGORY?.VIDEO || type === ASSET_CATEGORY?.AUDIO ? (
          <ReactPlayer
            url={url}
            controls
            playing
            width="100%"
            height={type === ASSET_CATEGORY?.AUDIO ? '53px' : '100%'}
          />
        ) : (
          <>
            {type === ASSET_CATEGORY.IMAGE ? (
              <Image
                src={url}
                alt={title}
                className="preview-image"
                width="100%"
              />
            ) : (
              <img
                alt={title}
                src={url}
                className="preview-image"
                width="100%"
              />
            )}
          </>
        )}
      </div>
    </Modal>
  );
};

export default PreviewModal;
