import { useMutation } from '@apollo/client';
import { Button, Modal, Tooltip } from 'antd';
import { ArrowClockwise } from 'phosphor-react';
import React, { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import {
  CLOUD_FRONT_CACHE_KEYS,
  MODULES,
  ROUTES
} from '../../../common/constants';
import { getImageUrl } from '../../../common/utils';
import FilterBar, { useFilterBar } from '../../../components/FilterBar';
import PageHeader from '../../../components/PageHeader';
import PageList from '../../../components/PageList';
import CollectionCard from '../../component/collectionModule/CollectionCard';
import { INVALIDATE_CLOUD_FRONT_CACHE } from '../../menus/graphql/Mutations';
import { DELETE_COLLECTION } from './graphql/Mutations';
import { GET_COLLECTIONS } from './graphql/Queries';

const CollectionItem = ({ id, thumbnail, title, onDelete, videosCount }) => {
  const history = useHistory();
  return (
    <>
      <CollectionCard
        blurHash={thumbnail?.blurhash}
        coverImg={getImageUrl(thumbnail?.url)}
        title={title}
        description={`${videosCount} Episodes`}
      />
      <div className="cbtn-wrapper">
        <Button
          type="text"
          onClick={() => history.push(`${ROUTES?.COLLECTIONS}/${id}/edit`)}
        >
          Edit
        </Button>
        <Button type="text" onClick={() => onDelete(id)}>
          Delete
        </Button>
      </div>
    </>
  );
};

const variablesSelector = ({
  limit,
  offset,
  search,
  sortField,
  sortOrder
}) => ({
  filter: {
    skip: offset,
    limit,
    search
  },
  sort: {
    sortOn: sortField,
    sortBy: sortOrder
  }
});

const dataSelector = ({ collectionsAdmin }) => ({
  data: collectionsAdmin?.collections ?? [],
  count: collectionsAdmin?.count ?? 0
});

const Collections = ({ history }) => {
  const ref = useRef();
  const [invalidateCache, { loading: isInvalidating }] = useMutation(
    INVALIDATE_CLOUD_FRONT_CACHE,
    {
      variables: { key: CLOUD_FRONT_CACHE_KEYS.OG_IMAGES }
    }
  );
  const filterProps = useFilterBar();

  const [deleteCollection] = useMutation(DELETE_COLLECTION);

  const handleShowModal = () => {
    history.push(`${ROUTES?.COLLECTIONS}/add`);
  };

  const handleDelete = (id) => {
    Modal.confirm({
      title: 'Are you sure, you want to delete this collection?',
      centered: true,
      okText: 'Yes',
      cancelText: 'No',
      okType: 'primary',
      onOk() {
        deleteCollection({
          variables: { id }
        })
          .then(({ errors }) => {
            if (ref.current.removeItem && !errors) {
              ref.current.removeItem(id);
            }
          })
          .catch();
      }
    });
  };

  return (
    <>
      <PageHeader menu={MODULES?.LABELS} handleShowModal={handleShowModal} />
      <FilterBar
        {...filterProps}
        SearchProps={{ placeholder: 'Search collections' }}
        show={{ listModes: false }}
      >
        <Tooltip title="Revalidate OG images" placement="left">
          <Button
            className="text-btn d-flex align-center justify-center"
            type="text"
            icon={<ArrowClockwise size={24} />}
            onClick={invalidateCache}
            loading={isInvalidating}
          />
        </Tooltip>
      </FilterBar>
      <PageList
        ref={ref}
        filters={filterProps.filters}
        query={GET_COLLECTIONS}
        variablesSelector={variablesSelector}
        dataSelector={dataSelector}
        limit={20}
        GridProps={{
          renderItem: (props) => (
            <CollectionItem {...props} onDelete={handleDelete} />
          ),
          breakPoints: 'auto'
        }}
      />
    </>
  );
};

export default Collections;
