import { gql } from '@apollo/client';

export const GET_UI_LABELS = gql`
  query uiLabelsAdmin($filter: UILabelsFilter) {
    uiLabelsAdmin(filter: $filter) {
      count
      uiLabels {
        key
        value
        description
        referenceImage {
          url
          blurhash
        }
        type
      }
    }
  }
`;

export const GET_UI_LABEL = gql`
  query uiLabelAdmin($key: String!) {
    uiLabelAdmin(where: { key: $key }) {
      value
      description
      referenceImage {
        id
        url
      }
      type
    }
  }
`;
