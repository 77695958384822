import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../../common/constants';
import AddEditImage from './AddEditImage';
import Images from './Images';

const ImagesWrapper = () => (
  <Switch>
    <Route path={ROUTES?.IMAGES} exact component={Images} />
    <Route path={`${ROUTES?.IMAGES}/add`} component={AddEditImage} />
    <Route path={`${ROUTES?.IMAGES}/:id/edit`} component={AddEditImage} />
    <Route
      exact
      path={`${ROUTES?.IMAGES}/:id`}
      render={() => <Redirect to={ROUTES?.IMAGES} />}
    />
  </Switch>
);

export default ImagesWrapper;
