import { Button, Space } from 'antd';
import {
  CaretDoubleDown,
  CaretDoubleUp,
  CaretDown,
  CaretUp,
  Pencil,
  Trash
} from 'phosphor-react';
import React from 'react';

function SectionWrapper({
  onDelete,
  onMoveTop,
  onMoveUp,
  onMoveDown,
  onMoveBottom,
  children,
  isFirst,
  isLast,
  onEdit,
  showEdit = false,
  showDelete = false,
  disableEdit = false,
  disableSort = false,
  disableDelete = false,
  loadingDelete = false
}) {
  return (
    <div className="section-wrapper  relative">
      <Space direction="vertical" className="delete-action mt-8">
        {showEdit && (
          <Button disabled={disableEdit} onClick={onEdit}>
            <Pencil size={14} weight="fill" />
          </Button>
        )}
        {showDelete && (
          <Button
            loading={loadingDelete}
            disabled={disableDelete}
            onClick={onDelete}
          >
            <Trash size={14} weight="fill" />
          </Button>
        )}
      </Space>

      <div className="section-action">
        <Button
          disabled={isFirst || disableSort}
          type="text"
          onClick={onMoveTop}
        >
          <CaretDoubleUp size={14} weight="bold" />
        </Button>
        <Button
          disabled={isFirst || disableSort}
          type="text"
          onClick={onMoveUp}
        >
          <CaretUp size={14} weight="bold" />
        </Button>
        <Button
          disabled={isLast || disableSort}
          type="text"
          onClick={onMoveDown}
        >
          <CaretDown size={14} weight="bold" />
        </Button>
        <Button
          disabled={isLast || disableSort}
          type="text"
          onClick={onMoveBottom}
        >
          <CaretDoubleDown size={14} weight="bold" />
        </Button>
      </div>
      <div className="section-block pt-4 pb-4">{children}</div>
    </div>
  );
}
export default SectionWrapper;
