import { gql } from '@apollo/client';

export const CREATE_AUTHOR = gql`
  mutation createAuthor($data: CreateAuthorInput!) {
    createAuthor(data: $data) {
      message
      author {
        id
        firstName
        lastName
        image {
          id
          url
        }
        thumbnail {
          id
          url
        }
        primaryColor
        createdAt
        updatedAt
      }
    }
  }
`;

export const UPDATE_AUTHOR = gql`
  mutation updateAuthor(
    $where: AuthorWhereUniqueInput!
    $data: UpdateAuthorInput!
  ) {
    updateAuthor(where: $where, data: $data) {
      message
      author {
        id
        firstName
        lastName
        image {
          id
          url
        }
        thumbnail {
          id
          url
        }
        primaryColor
        createdAt
        updatedAt
      }
    }
  }
`;
