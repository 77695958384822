import React from 'react';
import SectionHeader from '../SectionHeader/SectionHeader';
import CollectionCard from './CollectionCard';

function CollectionList({
  data = [],
  sectionHeading,
  headerLink,
  headerDescription,
  responsive,
  separator,
  collectionMd,
  collectionLg
}) {
  return (
    <section
      className={`collection-list-section ${
        responsive ? 'responsive-view' : ''
      } ${!separator ? 'separatoroff' : ''}`}
    >
      <div className="container-rnp">
        <SectionHeader
          heading={sectionHeading}
          headerLink={headerLink}
          headerDescription={headerDescription}
        />
        {data?.map((item) => {
          return (
            <div key={item?.id} className=" collection-list-item">
              <CollectionCard
                href={item?.href}
                collectionList
                videoCount={item?.videoCount}
                title={item?.title}
                description={item?.description}
                coverImg={item?.coverImg}
                blurHash={item?.blurHash}
                collectionMd={collectionMd}
                collectionLg={collectionLg}
              />
            </div>
          );
        })}
      </div>
    </section>
  );
}
export default CollectionList;
