import { gql } from '@apollo/client';

export const GET_AUTHORS = gql`
  query authorsAdmin(
    $filter: AuthorsFilter
    $sort: AuthorsSort
    $where: AuthorsWhereInput
  ) {
    authorsAdmin(filter: $filter, sort: $sort, where: $where) {
      count
      authors {
        id
        firstName
        lastName
        description
        slug
        createdAt
        image {
          id
          url
          blurhash
        }
        thumbnail {
          id
          url
          blurhash
        }
        primaryColor
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_AUTHOR = gql`
  query authorAdmin($where: AuthorWhereUniqueInput!) {
    authorAdmin(where: $where) {
      id
      firstName
      lastName
      description
      subTitle
      slug
      createdAt
      metaHeader
      metaFooter
      isActive
      image {
        id
        url
      }
      thumbnail {
        id
        url
      }
      primaryColor
      page {
        id
        isDefault
      }
      createdAt
      updatedAt
    }
  }
`;

export const GET_ASSETS = gql`
  query assets($filter: AssetsFilter, $sort: AssetsSort) {
    assets(filter: $filter, sort: $sort) {
      count
      assets {
        id
        title
        description
        categoryKey
        serviceVideoThumbnail
        url
        createdAt
      }
    }
  }
`;
