import React from 'react';
import SectionHeader from '../SectionHeader/SectionHeader';
import AuthorCard from './AuthorCard';

function AuthorList({
  sectionHeading,
  headerLink,
  headerDescription,
  data = [],
  responsive,
  separator
}) {
  return (
    <section
      className={`authorlist-section ${responsive ? 'responsive-view' : ''} ${
        !separator ? 'separatoroff' : ''
      }`}
    >
      <div className="container-rnp">
        <SectionHeader
          heading={sectionHeading}
          headerLink={headerLink}
          headerDescription={headerDescription}
        />

        {data?.map((item) => {
          return (
            <AuthorCard
              key={item?.id}
              listCard
              speaker={item.speaker}
              videoCount={item.videoCount}
              image={item.url}
              blurHash={item.blurHash}
              description={item.description}
            />
          );
        })}
      </div>
    </section>
  );
}
export default AuthorList;
