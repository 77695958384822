import { gql } from '@apollo/client';

export const DELETE_MENU = gql`
  mutation deleteMenu($id: ID!) {
    deleteMenu(where: { id: $id }) {
      message
    }
  }
`;

export const CREATE_MENU = gql`
  mutation createMenu($data: CreateMenuInput!) {
    createMenu(data: $data) {
      message
    }
  }
`;

export const UPDATE_MENU = gql`
  mutation updateMenu($id: ID!, $data: UpdateMenuInput!) {
    updateMenu(data: $data, where: { id: $id }) {
      message
    }
  }
`;

export const UPDATE_MENU_ORDER = gql`
  mutation updateMenuOrder($id: ID!, $order: Int!) {
    updateMenuOrder(where: { id: $id }, data: { order: $order }) {
      message
    }
  }
`;

export const INVALIDATE_CLOUD_FRONT_CACHE = gql`
  mutation invalidateCloudFrontCache($key: CloudFrontClearCacheKey!) {
    invalidateCloudFrontCache(key: $key) {
      message
    }
  }
`;
