import { Modal } from 'antd';
import { XCircle } from 'phosphor-react';
import React from 'react';
import LoaderComponent from './LoaderComponent';

const ModalComponent = (props) => {
  const {
    centered = true,
    maskClosable = false,
    loading,
    children,
    ...rest
  } = props;

  return (
    <Modal
      className="modal"
      closeIcon={<XCircle size={32} weight="fill" />}
      centered={centered}
      maskClosable={maskClosable}
      {...rest}
    >
      {loading ? <LoaderComponent setHeight={20} /> : <>{children}</>}
    </Modal>
  );
};

export default ModalComponent;
