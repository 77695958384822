import React from 'react';
import SectionHeader from '../SectionHeader/SectionHeader';
import TagCard from './TagCard';

function TagGrid({
  className,
  sectionHeading,
  headerLink,
  headerDescription,
  icon,
  responsive,
  data = []
}) {
  return (
    <div className={`tag_grid ${responsive ? 'responsive-view' : ''}`}>
      <SectionHeader
        heading={sectionHeading}
        headerLink={headerLink}
        headerDescription={headerDescription}
      />
      {data?.map((item) => {
        return (
          <div key={item.id}>
            <TagCard
              className={className}
              icon={icon}
              name={item?.name}
              href={item?.href}
            />
          </div>
        );
      })}
    </div>
  );
}
export default TagGrid;
