import { gql } from '@apollo/client';

export const CREATE_TAG = gql`
  mutation createTag($data: CreateTagInput!) {
    createTag(data: $data) {
      message
    }
  }
`;

export const UPDATE_TAG = gql`
  mutation updateTag($data: UpdateTagInput!, $key: String!) {
    updateTag(data: $data, where: { key: $key }) {
      message
    }
  }
`;
