import { Button, Typography } from 'antd';
import React from 'react';

const { Paragraph } = Typography;

function CustomTitle({ level, children, className }) {
  const TitleTag = `h${level}`;
  return <TitleTag className={className}>{children}</TitleTag>;
}
function Quote({ children, ...rest }) {
  return (
    <Typography {...rest}>
      <blockquote>{children}</blockquote>
    </Typography>
  );
}

function Wrapper({
  text,
  heading,
  bullet,
  level,
  paragraphSizeClass,
  children,
  blockQuote
}) {
  return (
    <div
      className={`${bullet && !blockQuote ? 'bulleted' : ''} ${
        heading ? `h${level}` : ''
      } ${text ? paragraphSizeClass : ''}`}
    >
      {children}
    </div>
  );
}

function Text({
  heading,
  level,
  size,
  alignment,
  text,
  toggles: {
    underline,
    bold,
    emphasize,
    strikeThrough,
    bullet,
    blockQuote
  } = {}
}) {
  if (!text) return false;
  const alignmentClass =
    {
      LEFT: 'text-left',
      RIGHT: 'text-right',
      CENTER: 'text-center'
    }[alignment] ?? '';
  const paragraphSizeClass =
    {
      EXTRA_LARGE: 'paragraph-l-xl',
      LARGE: 'paragraph-l',
      MEDIUM: 'paragraph-m',
      SMALL: 'paragraph-s'
    }[size] ?? '';

  const textClassNames = ` ${underline ? 'underline' : ''} ${
    bold ? 'bold' : ''
  } ${emphasize ? 'emphasized' : ''} ${
    strikeThrough ? 'strike' : ''
  }  ${alignmentClass} ${!heading ? paragraphSizeClass : ''}`;

  // eslint-disable-next-line no-nested-ternary
  const Component = heading ? CustomTitle : blockQuote ? Quote : Paragraph;

  const otherProps = heading ? { level } : {};

  return (
    <Wrapper
      heading={heading}
      text={text}
      level={level}
      paragraphSizeClass={paragraphSizeClass}
      bullet={bullet}
      blockQuote={blockQuote}
    >
      <Component className={textClassNames} {...otherProps}>
        {text}
      </Component>
    </Wrapper>
  );
}

function HTMLTextBlock({ title, paragraphs, cta, responsive }) {
  const linkSizeClass =
    {
      EXTRA_LARGE: 'extra-large',
      LARGE: 'large',
      MEDIUM: 'medium',
      SMALL: 'small'
    }[cta?.size] ?? '';
  const alignmentClass =
    {
      LEFT: 'text-left',
      RIGHT: 'text-right',
      CENTER: 'text-center'
    }[cta?.alignment] ?? '';

  return (
    <div className={`html-text-block  ${responsive ? 'responsive-view' : ' '}`}>
      <div className="container">
        {title && <Text heading {...title} />}
        {paragraphs?.map((paragraph, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <Text {...paragraph} key={i} />
        ))}
        {cta?.title && (
          <div className={alignmentClass}>
            <Button
              className={linkSizeClass}
              type={cta?.type}
              url={cta?.url}
              internalPageType={cta?.internalPageType}
              icon={cta?.asset}
              buttonType="link"
            >
              {cta?.title}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
export default HTMLTextBlock;
