import { CheckOutlined } from '@ant-design/icons';
import {
  DndContext,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core';
import {
  SortableContext,
  rectSortingStrategy,
  useSortable
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Button, Card, Space, theme } from 'antd';
import React, { useState } from 'react';
import VideoPlaceholder from '../../../../assets/images/imagePlaceholder.png';
import { COLLECTIONS_COLORS } from '../../../../common/constants';
import { getImageUrl } from '../../../../common/utils';
import Image from '../../../../components/Image';
import SelectableModal from '../../../../components/SelectableModal';
import Preview from '../../../videos/components/Preview';
import { GET_VIDEOS_MODULES } from '../../../videos/graphql/Queries';

export const ColorPicker = ({ onChange, value }) => {
  return (
    <div className="d-flex">
      {COLLECTIONS_COLORS?.map((color) => (
        <div
          key={color}
          className={`color-list ${color} ${
            value === color ? 'active-color' : ''
          }`}
          onClick={() => onChange(color)}
        />
      ))}
    </div>
  );
};

const VideoCard = ({ onSelect, selected, data, isLive = false }) => {
  return (
    <Card
      className="selectable-modal-card cs-card"
      cover={
        <>
          {selected && (
            <span className="checked-icon">
              <CheckOutlined />
            </span>
          )}
          <Image
            className="label-poster"
            src={
              getImageUrl(data?.imageThumbnail?.url, {
                height: 200,
                width: 200
              }) ?? VideoPlaceholder
            }
            isLive={isLive}
            alt=""
          />
        </>
      }
      onClick={() => onSelect(data)}
    >
      <Card.Meta title={<span className="label-title">{data?.title}</span>} />
    </Card>
  );
};

const Draggable = ({ allowDrag, colorText, onRemove, ...item }) => {
  const {
    setNodeRef,
    listeners,
    attributes,
    transform,
    transition,
    isDragging
  } = useSortable({
    id: item?.id,
    strategy: rectSortingStrategy,
    disabled: !allowDrag
  });
  const style = transform
    ? {
        opacity: isDragging ? 0.4 : undefined,
        transform: CSS.Translate.toString(transform),
        transition
      }
    : {
        ...(allowDrag && { cursor: 'move' })
      };

  const handlers = {
    ...attributes,
    ...listeners
  };

  return (
    <Preview
      ref={setNodeRef}
      onRemove={() => onRemove(item)}
      key={item?.id}
      style={style}
      bgImg={
        getImageUrl(item?.url, {
          height: 200,
          width: 200
        }) || VideoPlaceholder
      }
      {...handlers}
    >
      <Preview.Title color={colorText}>{item?.title}</Preview.Title>
    </Preview>
  );
};

export const SelectVideos = ({
  multiple = true,
  onChange,
  value,
  id: fieldId,
  allowDrag = false,
  isLive = false
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { colorText } = theme.useToken().token;
  const mouseSensor = useSensor(MouseSensor);
  const touchSensor = useSensor(TouchSensor);
  const sensors = useSensors(mouseSensor, touchSensor);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  const handleCardSelect = (item) => {
    if (multiple) {
      const isPresent = value?.find((items) => items.id === item.id);
      if (isPresent) {
        onChange(value?.filter((items) => items?.id !== item?.id));
      } else {
        onChange([
          ...value,
          {
            id: item?.id,
            url: item?.imageThumbnail?.url ?? '',
            title: item?.title
          }
        ]);
      }
      return;
    }
    onChange({
      id: item?.id,
      url: item?.imageThumbnail?.url ?? '',
      title: item?.title
    });
    closeModal();
  };

  const handleRemove = (item) => {
    if (multiple) {
      onChange(value?.filter((items) => items?.id !== item?.id));
    } else {
      onChange({
        id: '',
        url: '',
        title: ''
      });
    }
  };

  const handleDragEnd = ({ active, over }) => {
    if (!over) return;

    const source = active.id;
    const destination = over.id;

    if (source === destination) return;

    const sourceIndex = value?.findIndex(({ id }) => id === source);
    const destinationIndex = value?.findIndex(({ id }) => id === destination);

    const newItems = [...value];
    const item = newItems.splice(sourceIndex, 1);
    if (!item.length) return;
    newItems.splice(destinationIndex, 0, item[0]);

    onChange(newItems);
  };

  return (
    <>
      <SelectableModal
        title={multiple ? 'Select Videos' : 'Select Video'}
        open={isOpen}
        onClose={closeModal}
        query={GET_VIDEOS_MODULES}
        isLive={isLive}
        variablesSelector={(offset, limit, search) => ({
          filter: { search, skip: offset, limit, isLive }
        })}
        dataSelector={(data) => data?.videosAdmin?.videos ?? []}
        keys={{
          data: 'videosAdmin',
          records: 'videos',
          count: 'count'
        }}
        renderItem={(item) => {
          const isSelected = Array.isArray(value)
            ? value?.map((val) => val?.id).includes(item?.id)
            : value?.id === item?.id;
          return (
            <VideoCard
              isLive={isLive}
              data={item}
              selected={isSelected}
              onSelect={handleCardSelect}
            />
          );
        }}
      />
      <DndContext sensors={sensors} onDragEnd={handleDragEnd}>
        <Space wrap>
          {multiple ? (
            <>
              <SortableContext
                strategy={rectSortingStrategy}
                items={value?.map(({ id }) => id)}
              >
                <Space wrap>
                  {value?.length > 0 &&
                    value?.map((item) => (
                      <Draggable
                        key={item?.id}
                        onRemove={handleRemove}
                        colorText={colorText}
                        allowDrag={allowDrag}
                        {...item}
                      />
                    ))}
                </Space>
              </SortableContext>
            </>
          ) : (
            <>
              {!!value?.id && (
                <Preview
                  onRemove={handleRemove}
                  key={value?.id}
                  isLive={isLive}
                  bgImg={
                    getImageUrl(value?.url, {
                      height: 200,
                      width: 200
                    }) || VideoPlaceholder
                  }
                >
                  <Preview.Title color={colorText}>{value.title}</Preview.Title>
                </Preview>
              )}
            </>
          )}

          <Button id={fieldId} htmlType="button" onClick={openModal}>
            {value.length > 0 ? 'Change' : 'Select'}{' '}
            {multiple ? 'Videos' : 'Video'}
          </Button>
        </Space>
      </DndContext>
    </>
  );
};
