import { Progress } from 'antd';
import React from 'react';
import './styles/progressBar.less';

const ProgressBar = (props) => {
  const { progress, ...rest } = props;
  return (
    <Progress
      className="progress-bar"
      percent={progress}
      size="small"
      status="active"
      {...rest}
    />
  );
};

export default ProgressBar;
