import { useLazyQuery, useMutation } from '@apollo/client';
import { uniqueId } from 'lodash';
import moment from 'moment';
import { CaretRight, PlayCircle } from 'phosphor-react';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer
} from 'react';
import { useParams } from 'react-router-dom';
import Collectionimg from '../../../assets/images/collection.png';
import CollectionImg from '../../../assets/images/f-collection.png';
import FeaturedSpeakerImg from '../../../assets/images/fs.png';
import InfoImg from '../../../assets/images/information.png';
import Hvbg from '../../../assets/images/videos.png';
import {
  MENU_TYPES,
  MODULE_TYPES,
  THEMES,
  THEME_PICKER_COLORS
} from '../../../common/constants';
import { timeToString } from '../../../common/utils';
import {
  AuthorCarouselData,
  AuthorListData,
  CategorySliderData,
  FeaturedCarouselData,
  FeaturedCollectionCarouselData,
  TagCarouseldata,
  VideoCarouselData,
  collectionList,
  collectionSlider,
  faqData,
  quotedata
} from '../../component/DummyData';
import {
  DELETE_PAGE_MODULE,
  UPDATE_PAGE_MODULE_ORDER
} from '../graphql/Mutations';
import { GET_PAGE_DETAILS, GET_PAGE_MODULE_TYPES } from '../graphql/Queries';
import { usePagePreview } from './PreviewPageProvider';

const getDefaultValues = () => ({
  id: uniqueId(),
  placeholderData: {},
  moduleData: null
});

const DEFAULT_HEADER_KEYS = {
  sectionHeading: 'title',
  headerDescription: 'description'
};

const DEFAULT_VIDEO_DATA_KEYS = {
  date: 'videoDate',
  tags: 'videoTag',
  time: 'videoTimestamp',
  speaker: 'videoAuthor',
  category: 'videoTopic',
  tagBackground: 'videoTopic',
  collection: 'videoOrder'
};

const DEFAULT_FEATURED_VIDEO_DATA_KEYS = {
  date: 'videoDate',
  description: 'videoDescription',
  subHeading: 'videoAuthor',
  tagData: 'videoTag',
  tagName: 'videoTopic'
};

const DEFAULT_AUTHOR_DATA_KEYS = {
  videoCount: 'authorNumberOfVideos'
};

const DEFAULT_TOPIC_DATA_KEYS = {
  topic: 'topicTitle'
};

const KEY_BINDINGS = {
  [MODULE_TYPES.VIDEO_CONTINUE_WATCHING_CAROUSEL]: {
    headerKeys: { ...DEFAULT_HEADER_KEYS, headerLink: 'viewAll' },
    dataKeys: {
      ...DEFAULT_VIDEO_DATA_KEYS
    }
  },
  [MODULE_TYPES.VIDEO_WATCH_LATER_CAROUSEL]: {
    headerKeys: { ...DEFAULT_HEADER_KEYS, headerLink: 'viewAll' },
    dataKeys: {
      ...DEFAULT_VIDEO_DATA_KEYS
    }
  },
  [MODULE_TYPES.VIDEO_CAROUSEL]: {
    headerKeys: { headerDescription: 'description', headerLink: 'viewAll' },
    dataKeys: {
      ...DEFAULT_VIDEO_DATA_KEYS,
      defaultValue: 'videoProgressBar'
    }
  },
  [MODULE_TYPES.VIDEO_LIST]: {
    headerKeys: {
      headerDescription: 'description',
      separator: 'separator',
      headerLink: 'viewAll'
    },
    dataKeys: {
      ...DEFAULT_VIDEO_DATA_KEYS,
      defaultValue: 'videoProgressBar',
      description: 'videoDescription'
    }
  },
  [MODULE_TYPES.VIDEO_GRID]: {
    headerKeys: { ...DEFAULT_HEADER_KEYS, headerLink: 'viewAll' },
    dataKeys: {
      ...DEFAULT_VIDEO_DATA_KEYS,
      defaultValue: 'videoProgressBar'
    }
  },
  [MODULE_TYPES.FEATURED_VIDEO]: {
    headerKeys: { showCTA: 'videoCTA' },
    dataKeys: { ...DEFAULT_FEATURED_VIDEO_DATA_KEYS }
  },
  [MODULE_TYPES.FEATURED_VIDEO_SLIDER]: {
    headerKeys: {
      showIndicator: 'carouselIndicator',
      showCTA: 'videoCTA'
    },
    dataKeys: { ...DEFAULT_FEATURED_VIDEO_DATA_KEYS }
  },
  [MODULE_TYPES.VIDEO_PLAYER]: {
    headerKeys: {
      description: 'videoDescription',
      category: 'videoTopic',
      tags: 'videoTag',
      speakers: 'videoAuthor'
    }
  },
  [MODULE_TYPES.LIVE_VIDEO_PLAYER]: {
    headerKeys: {
      description: 'videoDescription',
      category: 'videoTopic',
      tags: 'videoTag',
      speakers: 'videoAuthor',
      timer: 'timer',
      primaryAction: 'primaryAction'
    }
  },
  [MODULE_TYPES.COLLECTION_CAROUSEL]: {
    headerKeys: { headerDescription: 'description', headerLink: 'viewAll' },
    dataKeys: {
      videoCount: 'collectionNumberOfVideos'
    }
  },
  [MODULE_TYPES.COLLECTION_LIST]: {
    headerKeys: {
      headerDescription: 'description',
      separator: 'separator',
      headerLink: 'viewAll'
    },
    dataKeys: {
      description: 'collectionDescription',
      videoCount: 'collectionNumberOfVideos'
    }
  },
  [MODULE_TYPES.COLLECTION_GRID]: {
    headerKeys: {
      sectionHeading: 'allText',
      headerDescription: 'description',
      headerLink: 'viewAll'
    },
    dataKeys: {
      videoCount: 'collectionNumberOfVideos'
    }
  },
  [MODULE_TYPES.FEATURED_COLLECTION]: {
    headerKeys: {
      description: 'collectionDescription'
    }
  },
  [MODULE_TYPES.FEATURED_COLLECTION_SLIDER]: {
    dataKeys: {
      title: 'collectionTitle',
      description: 'collectionDescription',
      img: 'collectionImage'
    }
  },
  [MODULE_TYPES.AUTHOR_CAROUSEL]: {
    headerKeys: {
      headerDescription: 'description',
      headerLink: 'viewAll'
    },
    dataKeys: {
      ...DEFAULT_AUTHOR_DATA_KEYS
    }
  },
  [MODULE_TYPES.AUTHOR_LIST]: {
    headerKeys: {
      headerDescription: 'description',
      separator: 'separator',
      headerLink: 'viewAll'
    },
    dataKeys: {
      ...DEFAULT_AUTHOR_DATA_KEYS,
      description: 'authorDescription'
    }
  },
  [MODULE_TYPES.AUTHOR_GRID]: {
    headerKeys: { headerDescription: 'description', headerLink: 'viewAll' },
    dataKeys: {
      ...DEFAULT_AUTHOR_DATA_KEYS
    }
  },
  [MODULE_TYPES.FEATURED_AUTHOR]: {
    headerKeys: {
      speakerImage: 'authorImage',
      speakerName: 'authorName'
    }
  },
  [MODULE_TYPES.TAG_CAROUSEL]: {
    headerKeys: { headerLink: 'viewAll' }
  },
  [MODULE_TYPES.TAG_CLOUD]: {
    headerKeys: { ...DEFAULT_HEADER_KEYS, headerLink: 'viewAll' }
  },
  [MODULE_TYPES.FEATURED_TAG]: {
    headerKeys: { title: 'tagTitle' }
  },
  [MODULE_TYPES.TOPIC_CAROUSEL]: {
    headerKeys: { headerDescription: 'description', headerLink: 'viewAll' },
    dataKeys: { ...DEFAULT_TOPIC_DATA_KEYS }
  },
  [MODULE_TYPES.TOPIC_LIST]: {
    headerKeys: { headerLink: 'viewAll' },
    dataKeys: { ...DEFAULT_TOPIC_DATA_KEYS }
  },
  [MODULE_TYPES.FEATURED_TOPIC]: {
    headerKeys: { topic: 'topicTitle' }
  },
  [MODULE_TYPES.TOPIC_GRID]: {
    headerKeys: { headerDescription: 'description', headerLink: 'viewAll' },
    dataKeys: { ...DEFAULT_TOPIC_DATA_KEYS }
  },
  [MODULE_TYPES.CTA_BLOCK]: {
    headerKeys: {
      description: 'description',
      secondary: 'secondaryAction',
      img: 'image'
    }
  },
  [MODULE_TYPES.CTA_EVENT_BLOCK]: {
    headerKeys: {
      description: 'description',
      secondary: 'secondaryAction',
      img: 'image',
      date: 'eventDetails',
      location: 'eventDetails'
    }
  },
  [MODULE_TYPES.CTA_BANNER_WITH_TEXT]: {
    headerKeys: {
      title: 'title',
      descriptions: 'description',
      ctaBackgroundImage: 'image'
    }
  },
  [MODULE_TYPES.HERO]: {
    headerKeys: {
      description: 'description',
      cta1: 'primaryAction',
      cta2: 'secondaryAction',
      date: 'textFields',
      location: 'textFields'
    }
  },
  [MODULE_TYPES.DONATION]: {
    headerKeys: {
      description: 'description',
      videoSectionTitle: 'secondaryTitleAndDescription',
      videoSectionSubTitle: 'secondaryTitleAndDescription'
    }
  },
  [MODULE_TYPES.COPY_HEAVY]: {
    headerKeys: {
      title: 'title',
      primaryButton: 'primaryAction',
      secondaryButton: 'secondaryAction',
      date: 'date',
      cmsContent: 'textFields',
      authorName: 'author'
    }
  },
  [MODULE_TYPES.COPY_HEAVY_HERO]: {
    headerKeys: {
      title: 'title',
      primaryButton: 'primaryAction',
      secondaryButton: 'secondaryAction',
      date: 'date',
      cmsContent: 'textFields',
      authorName: 'author'
    }
  },
  [MODULE_TYPES.QUOTE]: {
    headerKeys: {
      indicators: 'indicators'
    },
    dataKeys: {
      authName: 'authorName',
      authSubtitle: 'authorSubTitle'
    }
  },
  [MODULE_TYPES.INFORMATION]: {
    headerKeys: {
      description: 'description',
      primary: 'primaryAction',
      secondary: 'secondaryAction'
    }
  },
  [MODULE_TYPES.LEAD_GEN]: {
    headerKeys: {
      title: 'title',
      description: 'description'
    }
  },
  [MODULE_TYPES.CONTACT_US]: {
    headerKeys: {
      title: 'title',
      subtitle: 'description'
    }
  },
  [MODULE_TYPES.FAQ]: {
    headerKeys: {
      title: 'title',
      subTitle: 'description'
    }
  },
  [MODULE_TYPES.HTML_TEXT_BLOCK]: {
    headerKeys: {
      title: 'title',
      cta: 'cta'
    }
  }
};

export const getDefaultPlaceholderValues = (key) =>
  ({
    // Videos
    [MODULE_TYPES.VIDEO_CONTINUE_WATCHING_CAROUSEL]: {
      type: MODULE_TYPES.VIDEO_CONTINUE_WATCHING_CAROUSEL,
      ...getDefaultValues(),
      placeholderData: {
        data: VideoCarouselData.slice(0, 3),
        responsive: true,
        sectionHeading: 'VIDEO CONTINUE WATCHING CAROUSEL',
        headerLink: '/',
        headerDescription:
          'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'
      },
      keyBindings: KEY_BINDINGS[MODULE_TYPES.VIDEO_CONTINUE_WATCHING_CAROUSEL]
    },
    [MODULE_TYPES.VIDEO_WATCH_LATER_CAROUSEL]: {
      type: MODULE_TYPES.VIDEO_WATCH_LATER_CAROUSEL,
      ...getDefaultValues(),
      placeholderData: {
        data: VideoCarouselData.slice(0, 3),
        responsive: true,
        sectionHeading: 'VIDEO WATCH LATER CAROUSEL',
        headerLink: '/',
        headerDescription:
          'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'
      },
      keyBindings: KEY_BINDINGS[MODULE_TYPES.VIDEO_WATCH_LATER_CAROUSEL]
    },
    [MODULE_TYPES.VIDEO_CAROUSEL]: {
      type: MODULE_TYPES.VIDEO_CAROUSEL,
      ...getDefaultValues(),
      placeholderData: {
        data: VideoCarouselData.slice(0, 3),
        responsive: true,
        sectionHeading: 'VIDEO CAROUSEL',
        headerLink: '/',
        headerDescription:
          'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'
      },
      keyBindings: KEY_BINDINGS[MODULE_TYPES.VIDEO_CAROUSEL]
    },
    [MODULE_TYPES.VIDEO_LIST]: {
      type: MODULE_TYPES.VIDEO_LIST,
      ...getDefaultValues(),
      placeholderData: {
        data: VideoCarouselData.slice(0, 2),
        responsive: true,
        separator: true,
        sectionHeading: 'VIDEO LIST',
        headerDescription:
          'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        headerLink: '/'
      },
      keyBindings: KEY_BINDINGS[MODULE_TYPES.VIDEO_LIST]
    },
    [MODULE_TYPES.VIDEO_GRID]: {
      type: MODULE_TYPES.VIDEO_GRID,
      ...getDefaultValues(),
      placeholderData: {
        data: VideoCarouselData.slice(0, 4),
        responsive: true,
        sectionHeading: 'VIDEO GRID',
        headerDescription:
          'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        headerLink: '/'
      },
      keyBindings: KEY_BINDINGS[MODULE_TYPES.VIDEO_GRID]
    },
    [MODULE_TYPES.FEATURED_VIDEO]: {
      type: MODULE_TYPES.FEATURED_VIDEO,
      ...getDefaultValues(),
      placeholderData: {
        data: FeaturedCarouselData.slice(0, 1),
        responsive: true,
        showCTA: true
      },
      keyBindings: KEY_BINDINGS[MODULE_TYPES.FEATURED_VIDEO]
    },
    [MODULE_TYPES.FEATURED_VIDEO_SLIDER]: {
      type: MODULE_TYPES.FEATURED_VIDEO_SLIDER,
      ...getDefaultValues(),
      placeholderData: {
        data: FeaturedCarouselData.slice(0, 3),
        responsive: true,
        showIndicator: true,
        showCTA: true
      },
      keyBindings: KEY_BINDINGS[MODULE_TYPES.FEATURED_VIDEO_SLIDER]
    },
    [MODULE_TYPES.VIDEO_PLAYER]: {
      type: MODULE_TYPES.VIDEO_PLAYER,
      ...getDefaultValues(),
      placeholderData: {
        responsive: true,
        title: 'Orthodoxy',
        description:
          "John Mark Comer explains a historic and personal account of where he's seen deconstruction lead.",
        category: 'Spiritual Practices',
        // categoryTagStyle:'',
        tags: TagCarouseldata.slice(0, 5),
        speakers: [
          {
            id: 1,
            firstName: 'John',
            lastName: 'Mark',
            image: FeaturedSpeakerImg
          }
        ]
      },
      keyBindings: KEY_BINDINGS[MODULE_TYPES.VIDEO_PLAYER]
    },
    [MODULE_TYPES.LIVE_VIDEO_PLAYER]: {
      type: MODULE_TYPES.LIVE_VIDEO_PLAYER,
      ...getDefaultValues(),
      placeholderData: {
        responsive: true,
        title: 'Orthodoxy',
        description:
          "John Mark Comer explains a historic and personal account of where he's seen deconstruction lead.",
        category: 'Spiritual Practices',
        tags: TagCarouseldata.slice(0, 5),
        speakers: [
          {
            id: 1,
            firstName: 'John',
            lastName: 'Mark',
            image: FeaturedSpeakerImg
          }
        ],
        primaryAction: {
          title: 'cta'
        },
        timer: {
          startDate: timeToString(moment()?.add(3, 'day')?.format())
        }
      },
      keyBindings: KEY_BINDINGS[MODULE_TYPES.LIVE_VIDEO_PLAYER]
    },
    // Authors
    [MODULE_TYPES.AUTHOR_CAROUSEL]: {
      type: MODULE_TYPES.AUTHOR_CAROUSEL,
      ...getDefaultValues(),
      placeholderData: {
        data: AuthorCarouselData,
        responsive: true,
        sectionHeading: 'SPEAKER CAROUSEL',
        headerLink: '/',
        headerDescription:
          'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'
      },
      keyBindings: KEY_BINDINGS[MODULE_TYPES.AUTHOR_CAROUSEL]
    },
    [MODULE_TYPES.AUTHOR_GRID]: {
      type: MODULE_TYPES.AUTHOR_GRID,
      ...getDefaultValues(),
      placeholderData: {
        data: AuthorListData.slice(0, 4),
        responsive: true,
        sectionHeading: 'SPEAKER GRID',
        headerLink: '/',
        headerDescription:
          'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'
      },
      keyBindings: KEY_BINDINGS[MODULE_TYPES.AUTHOR_GRID]
    },
    [MODULE_TYPES.AUTHOR_LIST]: {
      type: MODULE_TYPES.AUTHOR_LIST,
      ...getDefaultValues(),
      placeholderData: {
        data: AuthorListData.slice(0, 2),
        responsive: true,
        separator: true,
        sectionHeading: 'SPEAKER LIST',
        headerDescription:
          'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        headerLink: '/'
      },
      keyBindings: KEY_BINDINGS[MODULE_TYPES.AUTHOR_LIST]
    },
    [MODULE_TYPES.FEATURED_AUTHOR]: {
      type: MODULE_TYPES.FEATURED_AUTHOR,
      ...getDefaultValues(),
      placeholderData: {
        responsive: true,
        speakerImage: FeaturedSpeakerImg,
        speakerName: 'john mark comer',
        speakerDescription:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy "
      },
      keyBindings: KEY_BINDINGS[MODULE_TYPES.FEATURED_AUTHOR]
    },
    // Tags
    [MODULE_TYPES.TAG_CAROUSEL]: {
      type: MODULE_TYPES.TAG_CAROUSEL,
      ...getDefaultValues(),
      placeholderData: {
        data: TagCarouseldata.slice(0, 6),
        className: 'text-md tag-xs bg-n-400 b-0 text-n-900 mr-4',
        responsive: true,
        headerLink: '/',
        icon: <CaretRight size={16} />
      },
      keyBindings: KEY_BINDINGS[MODULE_TYPES.TAG_CAROUSEL]
    },
    [MODULE_TYPES.TAG_CLOUD]: {
      type: MODULE_TYPES.TAG_CLOUD,
      ...getDefaultValues(),
      placeholderData: {
        sectionHeading: 'Tag Cloud',
        headerDescription:
          'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        headerLink: '/',
        data: TagCarouseldata.slice(0, 5),
        className: 'text-md tag-s bg-n-400 b-0 text-n-900 mr-4 tag-small',
        responsive: true,
        icon: <CaretRight size={16} />
      },
      keyBindings: KEY_BINDINGS[MODULE_TYPES.TAG_CLOUD]
    },
    [MODULE_TYPES.FEATURED_TAG]: {
      type: MODULE_TYPES.FEATURED_TAG,
      ...getDefaultValues(),
      placeholderData: {
        responsive: true,
        title: 'Deconstruction'
      },
      keyBindings: KEY_BINDINGS[MODULE_TYPES.FEATURED_TAG]
    },
    // Collections
    [MODULE_TYPES.COLLECTION_CAROUSEL]: {
      type: MODULE_TYPES.COLLECTION_CAROUSEL,
      ...getDefaultValues(),
      placeholderData: {
        data: collectionSlider.slice(0, 3),
        responsive: true,
        sectionHeading: 'COLLECTION CAROUSEL',
        headerLink: '/',
        headerDescription:
          'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'
      },
      keyBindings: KEY_BINDINGS[MODULE_TYPES.COLLECTION_CAROUSEL]
    },
    [MODULE_TYPES.COLLECTION_GRID]: {
      type: MODULE_TYPES.COLLECTION_GRID,
      ...getDefaultValues(),
      placeholderData: {
        data: collectionSlider.slice(0, 4),
        responsive: true,
        sectionHeading: 'COLLECTION GRID',
        headerDescription:
          'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        headerLink: '/'
      },
      keyBindings: KEY_BINDINGS[MODULE_TYPES.COLLECTION_GRID]
    },
    [MODULE_TYPES.COLLECTION_LIST]: {
      type: MODULE_TYPES.COLLECTION_LIST,
      ...getDefaultValues(),
      placeholderData: {
        data: collectionList.slice(0, 2),
        responsive: true,
        separator: true,
        sectionHeading: 'COLLECTION LIST',
        headerDescription:
          'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        headerLink: '/'
      },
      keyBindings: KEY_BINDINGS[MODULE_TYPES.COLLECTION_LIST]
    },
    [MODULE_TYPES.FEATURED_COLLECTION]: {
      type: MODULE_TYPES.FEATURED_COLLECTION,
      ...getDefaultValues(),
      placeholderData: {
        responsive: true,
        title: 'Title of Collection',
        videoCount: 10,
        description: `This course equips women to better underA podcast about following
            Jesus in the post-christian world. This Cultural Moment is a
            collaboration of Bridgetown Church in Portland, USA and Red Church
            in Melbourne, Australia.stand and live out their unique, God-given
            gifts.`,
        img: CollectionImg,
        button: 'View Podcast'
      },
      keyBindings: KEY_BINDINGS[MODULE_TYPES.FEATURED_COLLECTION]
    },
    [MODULE_TYPES.FEATURED_COLLECTION_SLIDER]: {
      type: MODULE_TYPES.FEATURED_COLLECTION_SLIDER,
      ...getDefaultValues(),
      placeholderData: {
        responsive: true,
        data: FeaturedCollectionCarouselData.slice(0, 3)
      },
      keyBindings: KEY_BINDINGS[MODULE_TYPES.FEATURED_COLLECTION_SLIDER]
    },
    // Topics
    [MODULE_TYPES.TOPIC_CAROUSEL]: {
      type: MODULE_TYPES.TOPIC_CAROUSEL,
      ...getDefaultValues(),
      placeholderData: {
        data: CategorySliderData.slice(0, 3),
        responsive: true,
        sectionHeading: 'TOPIC CAROUSEL',
        headerLink: '/',
        headerDescription:
          'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'
      },
      keyBindings: KEY_BINDINGS[MODULE_TYPES.TOPIC_CAROUSEL]
    },
    [MODULE_TYPES.TOPIC_GRID]: {
      type: MODULE_TYPES.TOPIC_GRID,
      ...getDefaultValues(),
      placeholderData: {
        data: CategorySliderData.slice(0, 4),
        responsive: true,
        sectionHeading: 'TOPIC GRID',
        headerLink: '/',
        headerDescription:
          'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'
      },
      keyBindings: KEY_BINDINGS[MODULE_TYPES.TOPIC_GRID]
    },
    [MODULE_TYPES.TOPIC_LIST]: {
      type: MODULE_TYPES.TOPIC_LIST,
      ...getDefaultValues(),
      placeholderData: {
        data: CategorySliderData.slice(0, 2),
        responsive: true,
        sectionHeading: 'TOPIC LIST',
        headerLink: '/',
        headerDescription:
          'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'
      },
      keyBindings: KEY_BINDINGS[MODULE_TYPES.TOPIC_LIST]
    },
    [MODULE_TYPES.FEATURED_TOPIC]: {
      type: MODULE_TYPES.FEATURED_TOPIC,
      ...getDefaultValues(),
      placeholderData: {
        topic: 'Topic',
        backgroundColor: 'bg-yellow-2',
        lending: true,
        responsive: true,
        href: '/'
      },
      keyBindings: KEY_BINDINGS[MODULE_TYPES.FEATURED_TOPIC]
    },
    // CTA
    [MODULE_TYPES.CTA_BLOCK]: {
      type: MODULE_TYPES.CTA_BLOCK,
      ...getDefaultValues(),
      placeholderData: {
        responsive: true,
        img: Collectionimg,
        title: 'Cta Title Goes Here',
        secondary: 'secondary',
        primary: 'primary',
        background: THEME_PICKER_COLORS[THEMES.BLUE].backgroundColor,
        contentlight: THEME_PICKER_COLORS[THEMES.BLUE].textLight,
        description: `Lorem ipsum dolor sit amet consectetur. Turpis sed turpis vitae
            pellentesque. Sapien integer placerat in in sit id sit donec amet.`
      },
      keyBindings: KEY_BINDINGS[MODULE_TYPES.CTA_BLOCK]
    },
    [MODULE_TYPES.CTA_BANNER]: {
      type: MODULE_TYPES.CTA_BANNER,
      ...getDefaultValues(),
      placeholderData: {
        responsive: true,
        src: Collectionimg
      },
      keyBindings: KEY_BINDINGS[MODULE_TYPES.CTA_BANNER]
    },
    [MODULE_TYPES.CTA_EVENT_BLOCK]: {
      type: MODULE_TYPES.CTA_EVENT_BLOCK,
      ...getDefaultValues(),
      placeholderData: {
        responsive: true,
        background: THEME_PICKER_COLORS[THEMES.BLUE].backgroundColor,
        contentlight: THEME_PICKER_COLORS[THEMES.BLUE].textLight,
        img: Hvbg,
        title: 'Cta Title Goes Here',
        date: 'April 28th @ 5:00 AM',
        location: 'Nashville, TN',
        secondary: 'Already Purchased',
        primary: 'Reserve Your Seat',
        description:
          'Lorem ipsum dolor sit amet consectetur. Dictum ullamcorper pretium turpis vitae tellus eget arcu amet faucibus.Lorem ipsum dolor sit amet consectetur.'
      },
      keyBindings: KEY_BINDINGS[MODULE_TYPES.CTA_EVENT_BLOCK]
    },
    [MODULE_TYPES.CTA_BANNER_WITH_TEXT]: {
      type: MODULE_TYPES.CTA_BANNER_WITH_TEXT,
      ...getDefaultValues(),
      placeholderData: {
        responsive: true,
        cta: 'Who We Are',
        background: THEME_PICKER_COLORS[THEMES.NEUTRAL_900].backgroundColor,
        ctaBackgroundDark: THEME_PICKER_COLORS[THEMES.NEUTRAL_900].textLight,
        ctaBackgroundImage: Hvbg,
        title:
          '“Why Hop4America is just incredible and trustworthy in a sentence or two right”',
        descriptions:
          'Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur. '
      },
      keyBindings: KEY_BINDINGS[MODULE_TYPES.CTA_BANNER_WITH_TEXT]
    },
    [MODULE_TYPES.HERO]: {
      type: MODULE_TYPES.HERO,
      ...getDefaultValues(),
      placeholderData: {
        responsive: true,
        imageSrc: Hvbg,
        title: 'Culture Summit 2023: Building Resilient Communities',
        description:
          'Lorem ipsum dolor sit amet consectetur. Dictum ullamcorper pretium turpis vitae tellus eget arcu amet faucibus.Lorem ipsum dolor sit amet consectetur.',
        date: 'April 28th @ 5:00 AM',
        location: 'Nashville, TN',
        cta1: (
          <>
            <PlayCircle className="mr-4 ml-0" size={22} /> Who We Are
          </>
        ),
        cta2: 'Learn more'
      },
      keyBindings: KEY_BINDINGS[MODULE_TYPES.HERO]
    },

    [MODULE_TYPES.DONATION]: {
      type: MODULE_TYPES.DONATION,
      ...getDefaultValues(),
      placeholderData: {
        responsive: true,
        title: 'Help Advance Good',
        description:
          'Lorem ipsum dolor sit amet consectetur. Dictum ullamcorper pretium turpis vitae tellus eget arcu amet faucibus.Lorem ipsum dolor sit amet consectetur.',
        videoSectionTitle: 'Initiate Important Conversations with Your Family',
        videoSectionSubTitle:
          'Lorem ipsum dolor sit amet consectetur. Dictum ullamcorper pretium turpis vitae tellus eget arcu amet faucibus.Lorem ipsum dolor sit amet consectetur.',
        videoTitle: `Watch the team share Hop4America's vision`
      },
      keyBindings: KEY_BINDINGS[MODULE_TYPES.DONATION]
    },
    [MODULE_TYPES.COPY_HEAVY]: {
      type: MODULE_TYPES.COPY_HEAVY,
      ...getDefaultValues(),
      placeholderData: {
        responsive: true,
        title: `Hop4America is a culture awareness platform that takes a unique approach
      to promoting cultural sensitivity and understanding.`,
        authorImage: FeaturedSpeakerImg,
        authorName: 'Malcolm Gladwell',
        authorSubTitle: 'Sub title of who they are',
        date: 'Date 12/12/22',
        secondaryButton: 'Learn More',
        primaryButton: 'Register Now',
        cmsContent: `Lorem ipsum dolor sit amet consectetur. Dictum ullamcorper pretium turpis vitae tellus eget arcu amet faucibus.Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur. Dictum ullamcorper pretium turpis vitae tellus eget arcu amet faucibus.\n\nLorem ipsum dolor sit amet consectetur.\n\nLorem ipsum dolor sit amet consectetur. Dictum ullamcorper pretium turpis vitae tellus eget arcu amet faucibus.Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur. Dictum ullamcorper pretium turpis vitae tellus eget arcu amet faucibus.Lorem ipsum dolor sit amet consectetur.`
      },
      keyBindings: KEY_BINDINGS[MODULE_TYPES.COPY_HEAVY]
    },
    [MODULE_TYPES.COPY_HEAVY_HERO]: {
      type: MODULE_TYPES.COPY_HEAVY_HERO,
      ...getDefaultValues(),
      placeholderData: {
        responsive: true,
        heroModule: true,
        backgroundImage: Hvbg,
        title: `Hop4America is a culture awareness platform that takes a unique approach
      to promoting cultural sensitivity and understanding.`,
        authorImage: FeaturedSpeakerImg,
        authorName: 'Malcolm Gladwell',
        authorSubTitle: 'Sub title of who they are',
        date: 'Date 12/12/22',
        secondaryButton: 'Learn More',
        primaryButton: 'Register Now',
        cmsContent: `Lorem ipsum dolor sit amet consectetur. Dictum ullamcorper pretium turpis vitae tellus eget arcu amet faucibus.Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur. Dictum ullamcorper pretium turpis vitae tellus eget arcu amet faucibus.\n\nLorem ipsum dolor sit amet consectetur.\n\nLorem ipsum dolor sit amet consectetur. Dictum ullamcorper pretium turpis vitae tellus eget arcu amet faucibus.Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur. Dictum ullamcorper pretium turpis vitae tellus eget arcu amet faucibus.Lorem ipsum dolor sit amet consectetur.`
      },
      keyBindings: KEY_BINDINGS[MODULE_TYPES.COPY_HEAVY_HERO]
    },
    [MODULE_TYPES.QUOTE]: {
      type: MODULE_TYPES.QUOTE,
      ...getDefaultValues(),
      placeholderData: {
        responsive: true,
        indicators: true,
        data: quotedata
      },
      keyBindings: KEY_BINDINGS[MODULE_TYPES.QUOTE]
    },
    [MODULE_TYPES.INFORMATION]: {
      type: MODULE_TYPES.INFORMATION,
      ...getDefaultValues(),
      placeholderData: {
        responsive: true,

        imgSrc: InfoImg,
        title: 'Initiate Important Conversations with Your Family',
        description: `Lorem ipsum dolor sit amet consectetur. Dictum ullamcorper pretium
        turpis vitae tellus eget arcu amet faucibus.Lorem ipsum dolor sit
        amet consectetur.`,
        primary: 'Learn More',
        secondary: 'Register Now'
      },
      keyBindings: KEY_BINDINGS[MODULE_TYPES.INFORMATION]
    },
    [MODULE_TYPES.LEAD_GEN]: {
      type: MODULE_TYPES.LEAD_GEN,
      ...getDefaultValues(),
      placeholderData: {
        responsive: true,
        // background="bg-red-3"
        // darkBackground
        title: 'Subscribe to Hop4America',
        description:
          'Lorem ipsum dolor sit amet consectetur. Dictum ullamcorper pretium turpis vitae tellus eget arcu amet faucibus.Lorem ipsum dolor sit amet consectetur.'
      },
      keyBindings: KEY_BINDINGS[MODULE_TYPES.LEAD_GEN]
    },
    [MODULE_TYPES.CONTACT_US]: {
      type: MODULE_TYPES.CONTACT_US,
      ...getDefaultValues(),
      placeholderData: {
        responsive: true,
        title: 'Contact Us',
        subtitle: `Lorem Ipsum dolor sit amen. Lorem Ipsum dolor sit amen. Lorem
  Ipsum dolor sit amen.`
      },
      keyBindings: KEY_BINDINGS[MODULE_TYPES.CONTACT_US]
    },
    [MODULE_TYPES.FAQ]: {
      type: MODULE_TYPES.FAQ,
      ...getDefaultValues(),
      placeholderData: {
        responsive: true,
        title: 'A few frequently asked questions',
        subTitle:
          'Lorem ipsum dolor sit amet consectetur. Dictum ullamcorper pretium turpis vitae tellus eget arcu amet faucibus.Lorem ipsum dolor sit amet consectetur. ',
        data: faqData
      },
      keyBindings: KEY_BINDINGS[MODULE_TYPES.FAQ]
    },
    [MODULE_TYPES.SPACER]: {
      type: MODULE_TYPES.SPACER,
      ...getDefaultValues(),
      placeholderData: {
        className: 's-40'
      }
    },
    [MODULE_TYPES.HTML_TEXT_BLOCK]: {
      type: MODULE_TYPES.HTML_TEXT_BLOCK,
      ...getDefaultValues(),
      placeholderData: {
        responsive: true,
        title: {
          text: 'Title',
          level: '2'
        },
        paragraphs: [
          {
            text: `Lorem Ipsum is simply dummy text of the printing and typesetting industry.`,
            size: 'small'
          },
          {
            text: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.`,
            size: 'small'
          }
        ],
        cta: {
          alignment: 'left',
          size: 'small',
          title: 'Read More'
        }
      },
      keyBindings: KEY_BINDINGS[MODULE_TYPES.HTML_TEXT_BLOCK]
    },
    [MODULE_TYPES.GOAL]: {
      type: MODULE_TYPES.GOAL,
      ...getDefaultValues(),
      placeholderData: {
        children: 'Goal'
      }
    },
    [MODULE_TYPES.MAP]: {
      type: MODULE_TYPES.MAP,
      ...getDefaultValues(),
      placeholderData: {
        children: 'Map'
      }
    },
    [MODULE_TYPES.DIVIDER]: {
      type: MODULE_TYPES.DIVIDER,
      ...getDefaultValues(),
      placeholderData: {
        children: 'Divider'
      }
    }
  }[key]);

const ctx = createContext();

export const useEditPage = () => useContext(ctx);

export const FORM_TYPES = {
  EDIT: 'EDIT',
  ADD: 'ADD'
};

const initialForm = {
  index: '',
  type: '',
  moduleId: '',
  defaultValues: null
};

const initialSettings = {
  title: '',
  slug: '/',
  description: '',
  metaHeader: '',
  metaFooter: '',
  previewImage: {
    id: '',
    url: ''
  },
  redirection: {
    type: MENU_TYPES.INTERNAL,
    internalPageType: null,
    url: '',
    newTab: false,
    active: false
  }
};

const initialState = {
  pageType: '',
  isDefaultPage: false,
  settings: {
    ...initialSettings
  },
  modules: [],
  selectionType: '',
  addIndex: '',
  form: {
    ...initialForm
  }
};

const SET_SELECTION_TYPE = 'SET_SELECTION_TYPE';
const SET_ADD_INDEX = 'SET_ADD_INDEX';
const ADD_MODULE = 'ADD_MODULE';
const DELETE_MODULE = 'DELETE_MODULE';
const MOVE_MODULE = 'MOVE_MODULE';
const SET_FORM = 'SET_FORM';
const SET_MODULES = 'SET_MODULES';
const CLEAR_RIGHT_PANEL = 'CLEAR_RIGHT_PANEL';
const UPDATE_MODULE = 'UPDATE_MODULE';
const UPDATE_MODULE_SETTINGS = 'UPDATE_MODULE_SETTINGS';
const UPDATE_SETTINGS = 'UPDATE_SETTINGS';
const SET_IS_DEFAULT_PAGE = 'SET_IS_DEFAULT_PAGE';
const SET_PAGE_TYPE = 'SET_PAGE_TYPE';

export const reducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_SELECTION_TYPE: {
      return { ...state, selectionType: payload, addIndex: 0 };
    }
    case SET_FORM: {
      return { ...state, form: { ...payload } };
    }
    case SET_ADD_INDEX: {
      return { ...state, addIndex: payload };
    }
    case ADD_MODULE: {
      const modulesCopy = [...state.modules];
      const addIndex = state.addIndex ?? modulesCopy.length;
      modulesCopy.splice(addIndex, 0, payload);
      return {
        ...state,
        modules: [...modulesCopy],
        selectionType: '',
        addIndex: 0
      };
    }
    case DELETE_MODULE: {
      const modulesCopy = [...state.modules];
      modulesCopy.splice(payload, 1);

      if (state.form.index === payload && state.selectionType) {
        return {
          ...state,
          modules: modulesCopy,
          form: { ...initialForm },
          selectionType: '',
          addIndex: ''
        };
      }
      return { ...state, modules: modulesCopy };
    }
    case MOVE_MODULE: {
      const { sourceIndex, destinationIndex } = payload;
      const modulesCopy = [...state.modules];

      const [sourceData] = modulesCopy.splice(sourceIndex, 1);
      modulesCopy.splice(destinationIndex, 0, sourceData);

      return {
        ...state,
        modules: modulesCopy
      };
    }
    case SET_MODULES: {
      return { ...state, modules: payload };
    }
    case CLEAR_RIGHT_PANEL: {
      return {
        ...state,
        selectionType: '',
        addIndex: '',
        form: {
          ...initialForm
        }
      };
    }
    case UPDATE_MODULE: {
      const newModules = state.modules.map((module, index) => {
        if (state.form.index === index) {
          return { ...module, moduleData: payload };
        }
        return module;
      });

      return {
        ...state,
        modules: newModules
      };
    }

    case UPDATE_MODULE_SETTINGS: {
      const newModules = state.modules.map((module, index) => {
        if (state.form.index === index) {
          return {
            ...module,
            moduleData: payload
              ? {
                  ...module?.moduleData,
                  moduleData: {
                    ...module?.moduleData?.moduleData,
                    settings: payload
                  }
                }
              : module?.moduleData
          };
        }
        return module;
      });

      return { ...state, modules: newModules };
    }
    case UPDATE_SETTINGS: {
      return {
        ...state,
        settings: payload
      };
    }
    case SET_IS_DEFAULT_PAGE: {
      return {
        ...state,
        isDefaultPage: payload
      };
    }
    case SET_PAGE_TYPE: {
      return {
        ...state,
        pageType: payload
      };
    }
    default:
      return state;
  }
};

const EditPageProvider = ({ children }) => {
  const { id } = useParams();
  const [state, dispatch] = useReducer(reducer, initialState);
  const isAdding = useMemo(() => state.form.type === FORM_TYPES.ADD, [state]);
  const isEditing = useMemo(() => state.form.type === FORM_TYPES.EDIT, [state]);

  const { refetch } = usePagePreview();

  const updateSettings = useCallback(
    ({
      slug,
      title,
      description,
      metaHeader,
      metaFooter,
      permissions,
      allowDelete,
      previewThumbnail,
      redirection
    }) => {
      const payload = {
        title: title || '',
        slug: `/${slug ?? ''}`,
        description: description || '',
        metaHeader: metaHeader || '',
        metaFooter: metaFooter || '',
        permissions: permissions || [],
        allowDelete,
        previewImage: {
          id: previewThumbnail?.id ?? '',
          url: previewThumbnail?.url ?? ''
        },
        redirection
      };
      dispatch({ type: UPDATE_SETTINGS, payload });
    },
    [dispatch]
  );

  const handleResponse = useCallback(
    (data) => {
      if (!data) return;
      const { pageAdmin } = data;
      if (!pageAdmin) return;

      const { modules, isDefault, type, ...settings } = pageAdmin;
      updateSettings(settings);
      const newModules = modules.map((module) => ({
        ...getDefaultPlaceholderValues(module.type),
        moduleData: module
      }));
      dispatch({ type: SET_IS_DEFAULT_PAGE, payload: isDefault });
      dispatch({ type: SET_MODULES, payload: newModules });
      dispatch({ type: SET_PAGE_TYPE, payload: type });
    },
    [dispatch, updateSettings]
  );

  const [fetchPageDetails, { loading: fetchingPageDetails }] = useLazyQuery(
    GET_PAGE_DETAILS,
    {
      fetchPolicy: 'no-cache'
    }
  );

  const [
    fetchPageModules,
    {
      loading: loadingPageModules,
      data: { pageModuleTypes: pageModules = [] } = {}
    }
  ] = useLazyQuery(GET_PAGE_MODULE_TYPES);

  const [updatePageModuleOrder, { loading: isMoving }] = useMutation(
    UPDATE_PAGE_MODULE_ORDER
  );

  const [deletePageModule] = useMutation(DELETE_PAGE_MODULE);

  useEffect(() => {
    fetchPageDetails({ variables: { id } })
      .then((res) => handleResponse(res.data))
      .catch();
  }, [id, handleResponse]);

  const setSelectionType = (type) => {
    dispatch({ type: SET_SELECTION_TYPE, payload: type });
  };

  const setAddIndex = (index) => {
    dispatch({ type: SET_ADD_INDEX, payload: index });
  };

  const addModule = (data) => {
    dispatch({ type: ADD_MODULE, payload: data });
  };

  const deleteModule = (index) => {
    dispatch({ type: DELETE_MODULE, payload: index });
  };

  const moveModule = useCallback(
    (sourceIndex, destinationIndex) => {
      const fallbackPayload = {
        sourceIndex: destinationIndex,
        destinationIndex: sourceIndex
      };
      dispatch({
        type: MOVE_MODULE,
        payload: { sourceIndex, destinationIndex }
      });
      const module = state.modules[sourceIndex];
      if (module && module?.moduleData) {
        updatePageModuleOrder({
          variables: {
            id: module.moduleData.id,
            order: destinationIndex + 1
          }
        })
          .then(() => {
            refetch();
          })
          .catch(() => {
            dispatch({ type: MOVE_MODULE, payload: fallbackPayload });
          });
      }
    },
    [state.modules]
  );

  const setForm = (data) => {
    dispatch({ type: SET_FORM, payload: { ...initialForm, ...data } });
  };

  const clearRightPanel = () => {
    dispatch({ type: CLEAR_RIGHT_PANEL });
  };

  const updateModule = (data) => {
    dispatch({ type: UPDATE_MODULE, payload: data });
  };

  const updateModuleSettings = (settings) => {
    dispatch({ type: UPDATE_MODULE_SETTINGS, payload: settings });
  };

  const handlers = {
    setSelectionType,
    fetchPageModules,
    setAddIndex,
    addModule,
    deleteModule,
    moveModule,
    setForm,
    clearRightPanel,
    updateModule,
    updatePageModuleOrder,
    deletePageModule,
    updateModuleSettings,
    updateSettings
  };

  const data = {
    ...state,
    isAdding,
    loadingPageModules,
    pageModules,
    fetchingPageDetails,
    isEditing,
    isMoving
  };

  return (
    <ctx.Provider value={{ ...data, ...handlers }}>{children}</ctx.Provider>
  );
};

export default EditPageProvider;
