import { NetworkStatus, useQuery } from '@apollo/client';
import { Col, Empty, Input, Modal, Row, Select, Spin, Typography } from 'antd';
import { get, isBoolean, isFunction, isObject } from 'lodash';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { LIVE_VIDEO_OPTIONS } from '../common/constants';
import InView from './InView';

const LIMIT = 12;

const useDebounce = (value, timeout = 500) => {
  const [state, setState] = useState(value);

  useEffect(() => {
    const id = setTimeout(() => {
      setState(value);
    }, timeout);

    return () => clearTimeout(id);
  }, [value, timeout]);

  return state;
};

const ModalContent = ({
  query,
  dataSelector,
  variablesSelector,
  renderItem,
  keyField = 'id',
  limit = LIMIT,
  keys,
  search,
  isLive
}) => {
  const ref = useRef();

  const debouncedSearch = useDebounce(search);

  const { fetchMore, data, networkStatus } = useQuery(query, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: variablesSelector(0, limit, debouncedSearch, {
      ...(isBoolean(isLive) && { isLive })
    })
  });

  const records = useMemo(() => (data ? dataSelector(data) : []), [data]);
  const count = useMemo(
    () => (data && isObject(keys) ? get(data, [keys.data, keys.count], 0) : 0),
    [data]
  );

  const hasMoreData = count > records.length;

  const loading =
    networkStatus === NetworkStatus.loading ||
    networkStatus === NetworkStatus.setVariables;
  const isFetchingMore = networkStatus === NetworkStatus.fetchMore;

  const fetchMoreRecords = useCallback(
    ({ offset, search: searchValue } = {}) => {
      fetchMore({
        variables: variablesSelector(offset, limit, searchValue, {
          ...(isBoolean(isLive) && { isLive })
        }),
        updateQuery: (prev, { fetchMoreResult }) => {
          if (
            fetchMoreResult &&
            keys &&
            isObject(keys) &&
            'data' in keys &&
            'records' in keys &&
            'count' in keys
          ) {
            return {
              ...prev,
              [keys.data]: {
                ...get(prev, keys.data),
                [keys.records]: [
                  ...get(prev, [keys.data, keys.records]),
                  ...get(fetchMoreResult, [keys.data, keys.records])
                ],
                [keys.count]: get(fetchMoreResult, [keys.data, keys.count])
              }
            };
          }
          return { ...prev };
        }
      });
    },
    [fetchMore]
  );

  return (
    <div className="selectable-modal-body" ref={ref}>
      {loading ? (
        <div className="selectable-modal-wrapper">
          <Spin size="large" />
        </div>
      ) : (
        <>
          {!records.length ? (
            <div className="selectable-modal-wrapper">
              <Empty />
            </div>
          ) : (
            <>
              <Row gutter={[16, 16]}>
                {records.map((record) => (
                  <Col
                    xs={8}
                    key={
                      isFunction(keyField) ? keyField(record) : record[keyField]
                    }
                  >
                    {renderItem(record)}
                  </Col>
                ))}
              </Row>
              {isFetchingMore && (
                <div className="d-flex justify-center pt-16">
                  <Spin />
                </div>
              )}
              {!isFetchingMore && hasMoreData && !loading && (
                <InView
                  ref={ref}
                  onChange={({ inView }) => {
                    if (inView) {
                      fetchMoreRecords({ offset: records.length, search });
                    }
                  }}
                />
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

const SelectableModal = ({
  open,
  onClose,
  title = 'Select Record',
  isLiveFilter = false,
  isEdit = false,
  isLive: isLiveProp = false,
  ...rest
}) => {
  const [search, setSearch] = useState('');
  const [isLive, setIsLive] = useState('');

  useEffect(() => {
    if (isLiveFilter) {
      setIsLive(isEdit ? isEdit && isLiveProp : '');
    }
  }, [isEdit, isLiveProp]);

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  return (
    <Modal
      open={open}
      title={title}
      onCancel={() => {
        onClose();
        setIsLive(isEdit ? isEdit && isLiveProp : '');
      }}
      width={900}
      className="selectable-modal"
      footer={null}
      destroyOnClose
    >
      <Row className="mb-12" justify="space-between">
        <Col xs={8}>
          <Input
            placeholder="Search..."
            value={search}
            onChange={handleSearchChange}
          />
        </Col>
        {isLiveFilter && (
          <Col xs={8}>
            <Row justify="end" align="middle">
              <Typography.Text className="sort-by-title" type="secondary">
                Filter By
              </Typography.Text>
              <Select
                dropdownMatchSelectWidth={false}
                value={isLive}
                disabled={isEdit}
                onChange={setIsLive}
              >
                {LIVE_VIDEO_OPTIONS?.map(({ name, value }) => (
                  <Select.Option key={value} value={value}>
                    {name}
                  </Select.Option>
                ))}
              </Select>
            </Row>
          </Col>
        )}
      </Row>
      {open && <ModalContent search={search} isLive={isLive} {...rest} />}
    </Modal>
  );
};

export default SelectableModal;
