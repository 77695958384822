import { Avatar, Carousel } from 'antd';
import React from 'react';

function QuoteSlider({ responsive, data = [], indicators }) {
  const settings = {
    dots: !!indicators,
    arrows: true,
    infinite: true,
    centerMode: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: false,
    draggable: true,
    loop: true
  };
  return (
    <section
      className={`quoteslider bg-n-300 ${responsive ? 'responsive-view' : ''}`}
    >
      <div className="container">
        <Carousel {...settings}>
          {data?.map((item) => {
            return (
              <div key={item.id} className="item">
                <div className="quote-wrapper">
                  {item.quote ? (
                    <div className="quote">{item.quote}</div>
                  ) : null}

                  <div className="author-wrapper">
                    <div className="auth-img">
                      {item.authImg ? (
                        <Avatar
                          src={item.authImg}
                          className="label-l"
                          size="large"
                        />
                      ) : null}
                    </div>
                    <div className="auth-description text-left">
                      {item.authName ? (
                        <div className="auth-name">{item.authName}</div>
                      ) : null}

                      {item.authSubtitle ? (
                        <div className="auth-subtitle">{item.authSubtitle}</div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </Carousel>
      </div>
    </section>
  );
}
export default QuoteSlider;
