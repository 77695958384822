import { gql } from '@apollo/client';

export const CREATE_COLLECTION = gql`
  mutation createCollection($data: CollectionCreateInput!) {
    createCollection(data: $data) {
      message
    }
  }
`;

export const UPDATE_COLLECTION = gql`
  mutation updateCollection($data: CollectionUpdateInput!, $id: ID!) {
    updateCollection(data: $data, where: { id: $id }) {
      message
    }
  }
`;

export const DELETE_COLLECTION = gql`
  mutation deleteCollection($id: ID!) {
    deleteCollection(where: { id: $id }) {
      message
    }
  }
`;
