import { UploadOutlined } from '@ant-design/icons';
import { Button, Space, Upload } from 'antd';
import React from 'react';

// eslint-disable-next-line import/prefer-default-export
export const FileUpload = ({
  title = 'Click to Upload',
  helperText,
  ...rest
}) => (
  <Upload beforeUpload={() => false} {...rest}>
    <Space>
      <Button icon={<UploadOutlined />}>{title}</Button>
      <span>({helperText})</span>
    </Space>
  </Upload>
);
