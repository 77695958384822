import { Button, Space, Spin, Tooltip, Typography } from 'antd';
import { ArrowClockwise } from 'phosphor-react';
import React from 'react';
import PreviewBar from '../../../../component/Previewbar';
import { usePagePreview } from '../../../context/PreviewPageProvider';
import { PlaceHolderComponents } from '../LeftPanel';

const ModuleComponent = ({ data, extraProps, type }) => {
  const Component = PlaceHolderComponents[type];
  if (!Component) return;

  return <Component {...data} {...extraProps} />;
};

const Preview = () => {
  const { modules, loading, refetch } = usePagePreview();

  return (
    <PreviewBar>
      <Space className="d-flex mb-8 justify-between">
        <Typography.Text>Preview</Typography.Text>
        <Space>
          <Tooltip title="Refresh preview" placement="left">
            <Button
              type="text"
              icon={<ArrowClockwise size={20} />}
              onClick={() => refetch()}
              disabled={loading}
            />
          </Tooltip>
        </Space>
      </Space>
      {loading ? (
        <Spin />
      ) : (
        <div className="modules">
          {modules.map((module) => (
            <ModuleComponent key={module.id} {...module} />
          ))}
        </div>
      )}
    </PreviewBar>
  );
};

export default Preview;
