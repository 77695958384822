import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../../common/constants';
import AddEditAuthor from './AddEditAuthor';
import Author from './Author';
import './author.less';

const AuthorWrapper = () => (
  <Switch>
    <Route path={ROUTES?.AUTHOR} exact component={Author} />
    <Route path={`${ROUTES?.AUTHOR}/add`} component={AddEditAuthor} />
    <Route path={`${ROUTES?.AUTHOR}/:id/edit`} component={AddEditAuthor} />
    <Route
      exact
      path={`${ROUTES?.AUTHOR}/:id`}
      render={() => <Redirect to={ROUTES?.AUTHOR} />}
    />
  </Switch>
);

export default AuthorWrapper;
