import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { Modal, Tooltip } from 'antd';
import moment from 'moment';
import { PencilSimpleLine, Trash } from 'phosphor-react';
import React, { useRef } from 'react';
import { ASSET_CATEGORY, MODULES, ROUTES } from '../../../common/constants';
import { getImageUrl } from '../../../common/utils';
import FilterBar, { useFilterBar } from '../../../components/FilterBar';
import Image from '../../../components/Image';
import PageHeader from '../../../components/PageHeader';
import PageList from '../../../components/PageList';
import CustomCard from '../../component/card/Cscard';
import { DELETE_ASSET } from '../graphql/Mutations';
import { GET_ASSETS } from '../graphql/Queries';

const { confirm } = Modal;

const variablesSelector = ({
  limit,
  offset,
  search,
  sortField,
  sortOrder
}) => ({
  filter: {
    limit,
    skip: offset,
    search,
    categoryKey: ASSET_CATEGORY?.IMAGE
  },
  sort: {
    sortOn: sortField,
    sortBy: sortOrder
  }
});

const dataSelector = ({ assets }) => ({
  data: assets?.assets || [],
  count: assets?.count || 0
});

function Images({ history }) {
  const ref = useRef();
  const filterProps = useFilterBar();
  const { trackPageView, trackEvent } = useMatomo();

  // Track page view
  React.useEffect(() => {
    trackPageView();
  }, []);

  const [deleteImage] = useMutation(DELETE_ASSET, {
    onError() {}
  });

  const handleShowModal = () => {
    trackEvent({ category: 'asset-image-page', action: 'add-image-asset' });
    history?.push(`${ROUTES?.IMAGES}/add`, { isUpdate: false });
  };

  const handleEdit = (id) => {
    history.push(`${ROUTES?.IMAGES}/${id}/edit`, {
      isUpdate: true,
      imageId: id
    });
  };

  const handlePreview = (item) => {
    if (ref.current.showPreview) {
      ref.current.showPreview({
        title: item.title,
        url: item.url,
        type: ASSET_CATEGORY.IMAGE
      });
    }
  };

  const handleDelete = async (id) => {
    trackEvent({
      category: 'asset-image-delete',
      action: `delete-image-asset ${id}`
    });
    confirm({
      title: 'Are you sure, you want to delete this image?',
      centered: true,
      okText: 'Yes',
      cancelText: 'No',
      okType: 'primary',
      async onOk() {
        deleteImage({
          variables: { where: { id } }
        })
          .then(({ errors }) => {
            if (ref.current.removeItem && !errors) {
              ref.current.removeItem(id);
            }
          })
          .catch();
      }
    });
  };

  const columns = [
    {
      title: 'Image',
      dataIndex: 'url',
      key: 'url',
      editable: true,
      width: '12%',
      render: (text, record) => (
        <Image
          blurHash={record?.blurhash}
          onClick={() => handlePreview(record)}
          className="group-img-table pointer"
          src={getImageUrl(text, { height: 50, width: 80 })}
          alt={`${record?.title}`}
        />
      )
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      width: '22%'
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: '22%'
    },
    {
      title: 'Action',
      dataIndex: 'operation',
      width: '12%',
      render: (text, record) => (
        <div>
          <Tooltip title="Edit Image">
            <EditOutlined
              onClick={(e) => {
                e.stopPropagation();
                handleEdit(record?.id);
              }}
            />
          </Tooltip>
          <span onClick={(e) => e.stopPropagation()}>
            <Tooltip title="Delete Image">
              <DeleteOutlined
                onClick={() => {
                  handleDelete(record?.id);
                }}
              />
            </Tooltip>
          </span>
        </div>
      )
    }
  ];

  return (
    <>
      <PageHeader menu={MODULES?.ASSETS} handleShowModal={handleShowModal} />
      <FilterBar
        {...filterProps}
        SearchProps={{ placeholder: 'Search Images' }}
      />
      <PageList
        ref={ref}
        filters={filterProps.filters}
        listMode={filterProps.listMode}
        query={GET_ASSETS}
        variablesSelector={variablesSelector}
        dataSelector={dataSelector}
        GridProps={{
          renderItem: (item) => (
            <CustomCard
              onClick={() => {
                handlePreview(item);
              }}
              alt={item?.title}
              src={getImageUrl(item?.url)}
              blurHash={item?.blurhash}
              title={moment(item?.createdAt).format('ll')}
              heading={item?.title}
              description={item?.description}
              primaryOnClick={(e) => {
                e?.stopPropagation();
                e?.preventDefault();
                handleEdit(item?.id);
              }}
              secondaryOnClick={(e) => {
                e?.stopPropagation();
                e?.preventDefault();
                handleDelete(item?.id);
              }}
              primary={
                <>
                  <PencilSimpleLine size={16} />
                  Edit
                </>
              }
              secondary={
                <>
                  <Trash size={16} />
                  Delete
                </>
              }
            />
          )
        }}
        TableProps={{ columns }}
      />
    </>
  );
}

export default Images;
