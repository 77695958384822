import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import AddEditVideo from './AddEditVideo';
import Videos from './Videos';

const VideosWrapper = () => {
  return (
    <Switch>
      <Route exact path={ROUTES?.VIDEOS_MODULE} component={Videos} />
      <Route
        exact
        path={`${ROUTES?.VIDEOS_MODULE}/add`}
        component={AddEditVideo}
      />
      <Route
        exact
        path={`${ROUTES?.VIDEOS_MODULE}/:videoId/edit`}
        component={AddEditVideo}
      />
      <Route
        exact
        path={`${ROUTES?.VIDEOS_MODULE}/:videoId`}
        render={() => <Redirect to={ROUTES?.VIDEOS_MODULE} />}
      />
    </Switch>
  );
};

export default VideosWrapper;
