import { Button } from 'antd';
import { Plus } from 'phosphor-react';
import React from 'react';

function SectionAdd({ onAdd, disabled }) {
  return (
    <div className="section-add-wrapper">
      <div className="section-add-block">
        <Button disabled={disabled} onClick={onAdd} type="text">
          <Plus size={25} weight="bold" />
        </Button>
        <div className="dash" />
      </div>
    </div>
  );
}
export default SectionAdd;
