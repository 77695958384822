import { useMutation } from '@apollo/client';
import { get } from 'lodash';
import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { authClient } from '../../apollo';
import { AppContext } from '../../AppContext';
import { ROUTES } from '../../common/constants';
import LoaderComponent from '../../components/LoaderComponent';
import { REFRESH_TOKEN } from './graphql/Mutations';

const RefreshToken = () => {
  const { initializeAuth, getRefreshToken, dispatch } = useContext(AppContext);
  const history = useHistory();
  const refreshToken = getRefreshToken();
  function successCallback(accessToken, userData) {
    initializeAuth(accessToken, userData);
  }

  const [refreshTokenMutate, { loading }] = useMutation(REFRESH_TOKEN, {
    client: authClient,
    onError() {} // Always write this method for error handling in all mutation.
  });

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await refreshTokenMutate({
          variables: {
            data: {
              refreshToken
            }
          }
        });
        if (response?.errors) {
          throw new Error('Invalid Refresh Token!');
        }
        const accessToken = get(response?.data, 'refreshToken.accessToken');
        const userData = get(response?.data, 'refreshToken.user');
        successCallback(accessToken, userData);
        history.push(ROUTES?.MAIN);
      } catch (error) {
        dispatch({ type: 'LOGOUT' });
        // eslint-disable-next-line no-undef
        window.location = '/login';
        return null;
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return <LoaderComponent />;

  return null;
};

export default RefreshToken;
