import { UploadOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Form, Input, Space, Upload } from 'antd';
import { isArray } from 'lodash';
import React, { useEffect, useState } from 'react';
import { ASSET_CATEGORY, MODULES, ROUTES } from '../../../common/constants';
import { fileUpload, formValidatorRules } from '../../../common/utils';
import LoaderComponent from '../../../components/LoaderComponent';
import PageHeader from '../../../components/PageHeader';
import ProgressBar from '../../../components/ProgressBar';
import history from '../../../historyData';
import { CREATE_ASSET, UPDATE_ASSET } from '../graphql/Mutations';
import { GET_ASSET, GET_UPLOAD_SIGNED_URL } from '../graphql/Queries';

const { TextArea } = Input;

const AddEditDocument = (props) => {
  const { location } = props;
  const { documentId, isUpdate } = location?.state;
  const [documentData, setDocumentData] = useState({});
  const [documentLoading, setDocumentLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [documentProgress, setDocumentProgress] = useState(undefined);
  const [fetchDocument] = useLazyQuery(GET_ASSET, {
    variables: { where: { id: documentId } },
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      setDocumentLoading(true);
      setDocumentData(res?.asset);
      setDocumentLoading(false);
    },
    onError: () => {
      setDocumentLoading(false);
    }
  });

  useEffect(() => {
    if (documentId) {
      fetchDocument();
    } else {
      setDocumentLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentId]);

  const [getSignedUrl] = useLazyQuery(GET_UPLOAD_SIGNED_URL);
  const [createAssetMutate] = useMutation(CREATE_ASSET, {
    onError() {
      setButtonLoading(false);
    }
  });
  const [updateAssetMutate] = useMutation(UPDATE_ASSET, {
    onError() {
      setButtonLoading(false);
    }
  });

  const handleCancel = () => {
    history?.replace(ROUTES?.DOCUMENT);
  };

  const onFinish = async (values) => {
    setButtonLoading(true);
    const { document } = values;
    try {
      const textData = {
        title: values?.title,
        description: values?.description
      };
      let key = null;
      let contentType = null;
      if (document?.length > 0) {
        setDocumentProgress(0);
        const documentFile = document?.[0]?.originFileObj;
        const fileName = document?.[0]?.name;
        contentType = document?.[0]?.type;
        const getSignedPutUrlResult = await getSignedUrl({
          variables: {
            data: {
              fileName: fileName?.replace(/ /g, '_'),
              contentType,
              assetType: ASSET_CATEGORY?.DOCUMENT
            }
          }
        });
        if (getSignedPutUrlResult?.data?.getAssetUploadSignedUrl) {
          await fileUpload(
            getSignedPutUrlResult?.data?.getAssetUploadSignedUrl?.signedUrl,
            documentFile,
            setDocumentProgress
          );
          key = getSignedPutUrlResult?.data?.getAssetUploadSignedUrl?.key;
        }
      }
      const media = {
        ...textData,
        categoryKey: ASSET_CATEGORY?.DOCUMENT,
        contentType,
        key
      };
      if (!key) {
        delete media?.key;
      }
      if (isUpdate) {
        const response = await updateAssetMutate({
          variables: {
            where: { id: documentId },
            data: {
              ...media
            }
          }
        });
        if (response?.data?.updateAsset) {
          setButtonLoading(false);
          history?.replace(ROUTES?.DOCUMENT);
        }
      } else {
        const response = await createAssetMutate({
          variables: {
            data: {
              ...media
            }
          }
        });
        if (response?.data?.createAsset) {
          history?.replace(ROUTES?.DOCUMENT);
          setDocumentProgress(undefined);
          setButtonLoading(false);
        }
      }
    } catch (error) {
      setDocumentProgress(undefined);
      setButtonLoading(false);
      return error;
    }
  };

  const initialValues = {
    ...documentData
  };

  if (documentLoading) {
    return <LoaderComponent />;
  }
  return (
    <>
      <PageHeader menu={MODULES?.ASSETS} />
      <div className="page-wrapper">
        <div className="page-wrapper-body">
          <Form
            initialValues={initialValues || { isActive: true }}
            name="create-asset"
            layout="vertical"
            className="add-edit-form"
            onFinish={onFinish}
          >
            <Form.Item
              name="title"
              label="Title"
              required
              rules={[
                formValidatorRules?.required('Please enter title!'),
                formValidatorRules?.maxLength(200, 'Max 200 characters allowed')
              ]}
            >
              <Input placeholder="Enter title" disabled={buttonLoading} />
            </Form.Item>

            <Form.Item name="description" label="Description">
              <TextArea
                rows={2}
                placeholder="Enter description"
                disabled={buttonLoading}
              />
            </Form.Item>

            <Form.Item
              name="document"
              label="Document"
              rules={[
                { required: !isUpdate, message: 'Please select document!' }
              ]}
              getValueFromEvent={(e) => {
                if (isArray(e)) {
                  return e;
                }
                return e?.fileList;
              }}
              valuePropName="fileList"
            >
              <Upload
                maxCount={1}
                disabled={buttonLoading}
                accept=".doc,.docx,.pdf,.csv,.txt,.xlsx"
                beforeUpload={() => false}
              >
                <Space>
                  <Button icon={<UploadOutlined />}>Click to Upload</Button>
                  <span>(.doc, .docx, .pdf, .csv, .txt, .xlsx)</span>
                </Space>
              </Upload>
            </Form.Item>

            {documentProgress >= 0 && (
              <Form.Item>
                <ProgressBar progress={documentProgress} />
              </Form.Item>
            )}

            <div className="d-flex button-section">
              <Space>
                <Form.Item>
                  <Button
                    loading={buttonLoading}
                    type="text"
                    htmlType="submit"
                    className="text-btn mr-8"
                    size="middle"
                  >
                    Save
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button
                    type="text"
                    className="text-btn2"
                    disabled={buttonLoading}
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                </Form.Item>
              </Space>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default AddEditDocument;
