import { Input, Radio, Select, Space, Tooltip, Typography } from 'antd';
import { MagnifyingGlass } from 'phosphor-react';
import React, { useState } from 'react';
import {
  LIST_OPTIONS,
  LIST_TYPES,
  ORDER,
  SORT_FIELD
} from '../common/constants';
import './styles/filterBar.less';

export const useFilterBar = ({
  defaultSearch = '',
  defaultSortOptions: {
    options: sortOptions = SORT_FIELD,
    order: sortOrder = ORDER[1].value,
    field: sortField = SORT_FIELD[0].value
  } = {}
} = {}) => {
  const [filters, setFilters] = useState({
    search: defaultSearch,
    sortField,
    sortOrder
  });
  const [listMode, setListMode] = useState(LIST_TYPES.GRID);

  const updateFilter = (key, value) =>
    setFilters((prev) => ({ ...prev, [key]: value }));

  const setSearch = (value) => updateFilter('search', value);
  const setSortField = (value) => updateFilter('sortField', value);
  const setSortOrder = (value) => updateFilter('sortOrder', value);

  return {
    filters,
    listMode,
    setSearch,
    setSortField,
    setSortOrder,
    setListMode,
    sortOptions
  };
};

const FilterBar = ({
  filters: { search, sortField, sortOrder },
  listMode,
  setSearch,
  setSortField,
  setSortOrder,
  setListMode,
  sortOptions,
  SearchProps = {},
  show: {
    search: showSearch = true,
    sort: showSort = true,
    listModes: showListModes = true
  } = {},
  extraFilters,
  children
}) => {
  return (
    <div className="assets-filter-wrapper">
      <div className="asset-filter-left">
        <div className="asset-filter">
          <Space size="small" wrap>
            {showSearch && (
              <Input
                allowClear
                className="search-component"
                name="search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                prefix={<MagnifyingGlass size={20} />}
                {...SearchProps}
              />
            )}
          </Space>
        </div>
      </div>
      <div className="asset-filter-right">
        <div className="asset-filter">
          <Space size="small" wrap>
            {showSort && (
              <>
                <Typography.Text className="sort-by-title" type="secondary">
                  Sort By
                </Typography.Text>
                <Select
                  dropdownMatchSelectWidth={false}
                  value={sortField}
                  onChange={setSortField}
                >
                  {sortOptions?.map(({ name, value }) => (
                    <Select.Option key={value} value={value}>
                      {name}
                    </Select.Option>
                  ))}
                </Select>
                <Select
                  dropdownMatchSelectWidth={false}
                  value={sortOrder}
                  onChange={setSortOrder}
                >
                  {ORDER.map(({ name, value }) => (
                    <Select.Option key={value} value={value}>
                      {name}
                    </Select.Option>
                  ))}
                </Select>
              </>
            )}
            {extraFilters}
            {showListModes && (
              <Radio.Group
                onChange={(e) => setListMode(e.target.value)}
                value={listMode}
              >
                {LIST_OPTIONS.map(({ name, icon, value }) => (
                  <Tooltip key={value} placement="bottom" title={name}>
                    <Radio.Button className="asset-radio-btn" value={value}>
                      {icon}
                    </Radio.Button>
                  </Tooltip>
                ))}
              </Radio.Group>
            )}
            {children}
          </Space>
        </div>
      </div>
    </div>
  );
};
export default FilterBar;
