import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { ROUTES } from '../../../common/constants';
import AddEditCollection from './AddEditCollection';
import Collections from './Collections';

const CollectionsWrapper = () => {
  return (
    <Switch>
      <Route exact path={ROUTES?.COLLECTIONS} component={Collections} />
      <Route
        exact
        path={`${ROUTES?.COLLECTIONS}/add`}
        component={AddEditCollection}
      />
      <Route
        exact
        path={`${ROUTES?.COLLECTIONS}/:collectionId/edit`}
        component={AddEditCollection}
      />
      <Route
        exact
        path={`${ROUTES?.COLLECTIONS}/:collectionId`}
        render={() => <Redirect to={ROUTES?.COLLECTIONS} />}
      />
    </Switch>
  );
};

export default CollectionsWrapper;
