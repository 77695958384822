import { Col, Collapse, ConfigProvider, Row } from 'antd';
import { CaretDown, CaretUp } from 'phosphor-react';
import React from 'react';
import WebStyle from '../../../../styles/style-web.json';

function FaqModule({ title, subTitle, responsive, data = [] }) {
  const { Panel } = Collapse;
  return (
    <ConfigProvider theme={WebStyle}>
      <section className={`faq-section ${responsive ? 'responsive-view' : ''}`}>
        <div className="container">
          <div className="container-marketing">
            <div className="faq-section-title mb-32">
              {title && <div className=" title">{title}</div>}
              {subTitle && <p className=" subtitle">{subTitle}</p>}
            </div>
            <div className="cs-accordion">
              <Row gutter={[{ md: 32, xs: 0 }, 0]}>
                <Col xs={{ span: 24 }} md={{ span: responsive ? 24 : 12 }}>
                  <Collapse
                    accordion
                    expandIconPosition="end"
                    // eslint-disable-next-line react/no-unstable-nested-components
                    expandIcon={({ isActive }) =>
                      isActive ? <CaretUp size={20} /> : <CaretDown size={20} />
                    }
                  >
                    {
                      // eslint-disable-next-line array-callback-return
                      data?.map((item, index) => {
                        if (index % 2 === 0) {
                          return (
                            <Panel header={item.heading} key={item.id}>
                              <div
                                className="m-0 paragraph-m"
                                // eslint-disable-next-line react/no-danger
                                dangerouslySetInnerHTML={{
                                  __html: item.cmsContent
                                }}
                              />
                            </Panel>
                          );
                        }
                      })
                    }
                  </Collapse>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: responsive ? 24 : 12 }}>
                  <Collapse
                    accordion
                    expandIconPosition="end"
                    // eslint-disable-next-line react/no-unstable-nested-components
                    expandIcon={({ isActive }) =>
                      isActive ? <CaretUp size={20} /> : <CaretDown size={20} />
                    }
                  >
                    {
                      // eslint-disable-next-line array-callback-return
                      data?.map((item, index) => {
                        if (index % 2 !== 0) {
                          return (
                            <Panel header={item.heading} key={item.id}>
                              <div
                                className="m-0 paragraph-m"
                                // eslint-disable-next-line react/no-danger
                                dangerouslySetInnerHTML={{
                                  __html: item.cmsContent
                                }}
                              />
                            </Panel>
                          );
                        }
                      })
                    }
                  </Collapse>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </section>
    </ConfigProvider>
  );
}
export default FaqModule;
