import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import AddPage from './component/AddPage';
import EditPage from './EditPage';
import Pages from './Pages';

const PagesWrapper = () => (
  <Switch>
    <Route path={ROUTES?.PAGES_MODULE} exact component={Pages} />
    <Route path={`${ROUTES?.PAGES_MODULE}/add`} exact component={AddPage} />
    <Route
      path={`${ROUTES?.PAGES_MODULE}/:id/edit`}
      exact
      component={EditPage}
    />
    <Route
      exact
      path={`${ROUTES?.PAGES_MODULE}/:id`}
      render={() => <Redirect to={ROUTES?.PAGES_MODULE} />}
    />
  </Switch>
);

export default PagesWrapper;
