import React from 'react';
import Image from '../../../components/Image';

function BannerImage({ src, blurHash, responsive }) {
  return (
    <div className={`banner bg-n-600 ${responsive ? 'responsive-view' : ''}`}>
      <Image blurHash={blurHash} src={src} alt="banner image" />
    </div>
  );
}
export default BannerImage;
