import { Carousel } from 'antd';
import React from 'react';
import FeaturedCollection from './FeaturedCollection';

function FeaturedCollectionCarousel({ responsive, data = [] }) {
  const settings = {
    dots: !!responsive,
    arrows: !responsive,
    infinite: true,
    centerMode: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: false,
    draggable: true,
    loop: true,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
          centerPadding: '0',
          dots: true,
          arrows: false
        }
      }
    ]
  };
  return (
    <section className="featurecollection-slider">
      <Carousel {...settings}>
        {data?.map((item) => {
          return (
            <FeaturedCollection
              responsive={responsive}
              key={item?.id}
              img={item?.img}
              blurHash={item.blurHash}
              title={item?.title}
              videoCount={item?.videoCount}
              description={item?.description}
              button={item?.button}
            />
          );
        })}
      </Carousel>
    </section>
  );
}
export default FeaturedCollectionCarousel;
