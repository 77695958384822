import React from 'react';
import SectionHeader from '../SectionHeader/SectionHeader';
import CategoryCard from './CategoryCard';

function CategoryList({
  sectionHeading,
  headerLink,
  headerDescription,
  data = [],
  responsive
}) {
  return (
    <section className={`category-list ${responsive ? 'responsive-view' : ''}`}>
      <div className="container">
        <SectionHeader
          heading={sectionHeading}
          headerLink={headerLink}
          headerDescription={headerDescription}
        />

        {data?.map((item) => {
          return (
            <CategoryCard
              key={item?.id}
              list
              href={item?.href}
              topic={item?.topic}
              backgroundColor={item?.backgroundColor}
              textColor={item?.textColor}
            />
          );
        })}
      </div>
    </section>
  );
}
export default CategoryList;
