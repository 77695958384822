import { Typography } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import DefaultCollectionImg from '../../../assets/images/collection.png';
import Image from '../../../components/Image';

const { Text } = Typography;
function AuthorCard({
  speaker,
  videoCount,
  listCard,
  description,
  image,
  blurHash,
  href = '#'
}) {
  return (
    <div className={`authorcard ${listCard ? 'authorcardList' : null}`}>
      <Link to={href} className="auther-img relative block">
        <Image
          blurHash={blurHash}
          src={image || DefaultCollectionImg}
          alt={speaker}
        />
      </Link>
      <div className="author-description ">
        {speaker ? (
          <Text className="block text-md-bold  text-n-1000 speaker">
            {speaker}
          </Text>
        ) : null}
        {videoCount !== undefined && videoCount !== null ? (
          <div className="text-tiny text-n-1000 m-0 videocount">
            {videoCount} Videos
          </div>
        ) : null}

        {description ? (
          <div className="text-small text-n-1000 m-0 description">
            {description}
          </div>
        ) : null}
      </div>
    </div>
  );
}
export default AuthorCard;
