import { gql } from '@apollo/client';

export const GET_COLLECTIONS = gql`
  query collectionsAdmin($filter: CollectionsFilter, $sort: CollectionsSort) {
    collectionsAdmin(filter: $filter, sort: $sort) {
      count
      collections {
        id
        title
        videosCount
        thumbnail {
          url
          blurhash
        }
      }
    }
  }
`;

export const GET_COLLECTION = gql`
  query collectionAdmin($id: ID!) {
    collectionAdmin(where: { id: $id }) {
      id
      title
      slug
      status
      primaryColor
      metaHeader
      metaFooter
      thumbnail {
        id
        url
      }
      page {
        id
        isDefault
      }
    }
    collectionVideos(where: { id: $id }) {
      id
      title
      videoThumbnail {
        url
      }
    }
  }
`;
