import { EditOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Select, Tooltip } from 'antd';
import moment from 'moment';
import { ArrowClockwise, PencilSimpleLine } from 'phosphor-react';
import React, { useMemo, useRef, useState } from 'react';
import ImagePlaceholder from '../../../assets/images/imagePlaceholder.png';
import {
  ASSET_CATEGORY,
  AUTHOR_SORT_FIELD,
  CLOUD_FRONT_CACHE_KEYS,
  MODULES,
  ORDER,
  ROUTES
} from '../../../common/constants';
import { getImageUrl } from '../../../common/utils';
import FilterBar, { useFilterBar } from '../../../components/FilterBar';
import Image from '../../../components/Image';
import PageHeader from '../../../components/PageHeader';
import PageList from '../../../components/PageList';
import CustomCard from '../../component/card/Cscard';
import { INVALIDATE_CLOUD_FRONT_CACHE } from '../../menus/graphql/Mutations';
import { GET_AUTHORS } from './graphql/Queries';

const variablesSelector = ({
  limit,
  offset,
  search,
  sortField,
  sortOrder,
  isActive
}) => ({
  filter: {
    limit,
    skip: offset,
    search
  },
  sort: {
    sortOn: sortField,
    sortBy: sortOrder
  },
  where: {
    isActive
  }
});

const dataSelector = ({ authorsAdmin }) => ({
  data: authorsAdmin?.authors || [],
  count: authorsAdmin?.count || 0
});

const SPEAKER_ACTIVE_TYPES = {
  ALL: 'ALL',
  ACTIVE: 'ACTIVE',
  NOT_ACTIVE: 'NOT_ACTIVE'
};

const SPEAKER_ACTIVE_VALUES = {
  [SPEAKER_ACTIVE_TYPES.ALL]: null,
  [SPEAKER_ACTIVE_TYPES.ACTIVE]: true,
  [SPEAKER_ACTIVE_TYPES.NOT_ACTIVE]: false
};

const SPEAKER_ACTIVE_OPTIONS = [
  {
    label: 'All',
    value: SPEAKER_ACTIVE_TYPES.ALL
  },
  {
    label: 'Active',
    value: SPEAKER_ACTIVE_TYPES.ACTIVE
  },
  {
    label: 'Not Active',
    value: SPEAKER_ACTIVE_TYPES.NOT_ACTIVE
  }
];

function Author({ history }) {
  const ref = useRef();
  const [speakerActive, setSpeakerActive] = useState(SPEAKER_ACTIVE_TYPES.ALL);
  const [invalidateCache, { loading: isInvalidating }] = useMutation(
    INVALIDATE_CLOUD_FRONT_CACHE,
    {
      variables: { key: CLOUD_FRONT_CACHE_KEYS.OG_IMAGES }
    }
  );
  const filterProps = useFilterBar({
    defaultSortOptions: {
      options: AUTHOR_SORT_FIELD,
      order: ORDER[1].value,
      field: AUTHOR_SORT_FIELD[0].value
    }
  });

  const handleShowModal = () => {
    history?.push(`${ROUTES?.AUTHOR}/add`, { isUpdate: false });
  };

  const handlePreview = (item) => {
    if (ref.current.showPreview) {
      ref.current.showPreview({
        title: `${item.firstName} ${item.lastName}`,
        url: item.image.url,
        type: ASSET_CATEGORY.IMAGE
      });
    }
  };

  const handleEdit = (id) => {
    history.push(`${ROUTES?.AUTHOR}/${id}/edit`, {
      isUpdate: true,
      authorId: id
    });
  };

  const columns = [
    {
      title: 'Image',
      dataIndex: 'image',
      key: 'image',
      editable: true,
      width: '12%',
      render: (text, record) => (
        <Image
          blurHash={record.image?.blurhash}
          onClick={() => handlePreview(record)}
          className="group-img-table pointer"
          src={
            text?.url
              ? getImageUrl(text?.url, { height: 50, width: 80 })
              : ImagePlaceholder
          }
          alt={`${record?.title}`}
        />
      )
    },
    {
      title: 'FirstName',
      dataIndex: 'firstName',
      key: 'firstName',
      width: '22%'
    },
    {
      title: 'LastName',
      dataIndex: 'lastName',
      key: 'lastName',
      width: '22%'
    },
    {
      title: 'Action',
      dataIndex: 'operation',
      width: '12%',
      render: (text, record) => (
        <div>
          <Tooltip title="Edit Speaker">
            <EditOutlined
              onClick={(e) => {
                e.stopPropagation();
                handleEdit(record?.id);
              }}
            />
          </Tooltip>
        </div>
      )
    }
  ];

  const filters = useMemo(
    () => ({
      ...filterProps.filters,
      isActive: SPEAKER_ACTIVE_VALUES[speakerActive]
    }),
    [speakerActive, filterProps.filters]
  );

  const handleSpeakerActiveChange = (value) => setSpeakerActive(value);

  return (
    <>
      <PageHeader menu={MODULES?.LABELS} handleShowModal={handleShowModal} />
      <FilterBar
        {...filterProps}
        SearchProps={{ placeholder: 'Search Speakers' }}
        extraFilters={
          <Select
            value={speakerActive}
            dropdownMatchSelectWidth={false}
            options={SPEAKER_ACTIVE_OPTIONS}
            onChange={handleSpeakerActiveChange}
          />
        }
      >
        <Tooltip title="Revalidate OG images" placement="left">
          <Button
            className="text-btn d-flex align-center justify-center"
            type="text"
            icon={<ArrowClockwise size={24} />}
            onClick={invalidateCache}
            loading={isInvalidating}
          />
        </Tooltip>
      </FilterBar>
      <PageList
        ref={ref}
        filters={filters}
        listMode={filterProps.listMode}
        query={GET_AUTHORS}
        variablesSelector={variablesSelector}
        dataSelector={dataSelector}
        GridProps={{
          renderItem: (item) => (
            <CustomCard
              onClick={() => {
                handlePreview(item);
              }}
              alt={`${item?.firstName} ${item?.lastName}`}
              blurHash={item?.image?.blurhash}
              src={
                item?.image?.url
                  ? getImageUrl(item?.image?.url)
                  : ImagePlaceholder
              }
              title={moment(item?.createdAt).format('ll')}
              heading={item?.firstName}
              description={item?.lastName}
              primaryOnClick={(e) => {
                e?.stopPropagation();
                e?.preventDefault();
                handleEdit(item?.id);
              }}
              primary={
                <>
                  <PencilSimpleLine size={16} />
                  Edit
                </>
              }
            />
          )
        }}
        TableProps={{ columns }}
      />
    </>
  );
}

export default Author;
