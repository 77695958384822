import { DeleteOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import { isFunction } from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';

function CategoryCard({
  href,
  grid,
  lending,
  topic,
  backgroundColor = 'bg-ivory',
  textColor = 'text-n-1000',
  responsive,
  onDelete
}) {
  return (
    <Link
      className={`category-card ${backgroundColor} ${textColor} ${
        grid ? 'categorygrid' : ''
      }  ${lending ? 'categorylending' : ''} ${
        responsive ? 'responsive-view' : ''
      }`}
      to={href}
    >
      <div className="category-title">
        {topic ? <div className="topic">{topic}</div> : null}
      </div>
      {isFunction(onDelete) && (
        <Tooltip title="Delete Topic">
          <Button
            type="text"
            className="btn-text btn-delete"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onDelete();
            }}
          >
            <DeleteOutlined />
          </Button>
        </Tooltip>
      )}
    </Link>
  );
}
export default CategoryCard;
