import { CloseOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import React, { forwardRef } from 'react';
import LiveLogo from '../../../assets/images/live-new.png';

const Preview = forwardRef(
  ({ bgImg, children, onRemove, isLive = false, ...rest }, ref) => {
    return (
      <div className="preview-card" ref={ref} {...rest}>
        {isLive && (
          <img alt="live-logo" src={LiveLogo} className="live-absolute" />
        )}
        <img alt="bg-img" src={bgImg} />
        {onRemove && (
          <button type="button" className="remove-icon" onClick={onRemove}>
            <CloseOutlined />
          </button>
        )}
        {children}
      </div>
    );
  }
);

Preview.Title = ({ bgColor = 'bg-n-500', color, children }) => {
  return (
    <Typography.Text
      className={`${bgColor}`}
      style={{
        color
      }}
    >
      {children}
    </Typography.Text>
  );
};

export default Preview;
