import { Breadcrumb } from 'antd';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './styles/breadCrumb.less';

const BreadCrumb = ({ menu }) => {
  const location = useLocation();
  const breadCrumbView = () => {
    const { pathname } = location;
    const pathnames = pathname?.split('/')?.filter((item) => item);
    const smallCase = (s) =>
      s?.charAt(0)?.toUpperCase() + s?.slice(1)?.toLowerCase();
    const capitalize = (s) => s?.charAt(0)?.toUpperCase() + s?.slice(1);
    return (
      <div>
        <Breadcrumb className="breadcrumb-item">
          {pathnames && pathnames?.length > 0 ? (
            <Breadcrumb.Item className="clickable">
              {smallCase(menu)}
            </Breadcrumb.Item>
          ) : (
            <Breadcrumb.Item className="active">
              {smallCase(menu)}
            </Breadcrumb.Item>
          )}
          {pathnames &&
            pathnames?.map((name, index) => {
              const routeTo = `/${pathnames?.slice(0, index + 1)?.join('/')}`;
              const isLast = index === pathnames.length - 1;
              return isLast ? (
                <Breadcrumb.Item key={name} className="active">
                  {capitalize(name)}
                </Breadcrumb.Item>
              ) : (
                <Breadcrumb.Item key={name}>
                  <Link className="clickable" to={`${routeTo}`}>
                    {capitalize(name)}
                  </Link>
                </Breadcrumb.Item>
              );
            })}
        </Breadcrumb>
      </div>
    );
  };

  return <>{breadCrumbView()}</>;
};

export default BreadCrumb;
