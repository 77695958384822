import { useMutation } from '@apollo/client';
import { Button, Form, InputNumber, Space, Typography } from 'antd';
import React, { useEffect } from 'react';
import { MODULE_TYPES, STATUS_TYPES } from '../../../../../common/constants';
import { FORM_TYPES } from '../../../context/EditPageProvider';
import {
  CREATE_PAGE_MODULE,
  UPDATE_PAGE_MODULE
} from '../../../graphql/Mutations';
import { KeyInput, ModuleFields } from './FormInputs';

const initialValues = {
  title: '',
  description: '',
  status: STATUS_TYPES.PUBLISHED,
  permissions: [],
  config: {
    key: '',
    min: '10',
    max: '10000'
  }
};

const MODULE_KEYS = {
  [MODULE_TYPES.DONATION]: 'donationModule'
};

const CONFIG_TITLE = {
  [MODULE_TYPES.DONATION]: 'Donation Configs'
};

const CurrencyInput = (props) => {
  return (
    <InputNumber
      min={1}
      className="w-full"
      formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
      parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
      stringMode
      {...props}
    />
  );
};

const DonationForm = ({
  form: { type: formType, moduleId, defaultValues, index: order },
  pageId,
  type,
  onCancel,
  onSuccess,
  onSettingsChange
}) => {
  const [form] = Form.useForm();
  const isEdit = formType === FORM_TYPES.EDIT;

  const settingsProps = Form.useWatch(['settings'], form);

  useEffect(() => {
    if (settingsProps) {
      onSettingsChange(settingsProps);
    }
  }, [settingsProps]);

  const [addEditModule, { loading }] = useMutation(
    isEdit ? UPDATE_PAGE_MODULE : CREATE_PAGE_MODULE
  );

  useEffect(() => {
    if (moduleId && defaultValues && formType === FORM_TYPES.EDIT && form) {
      form.setFieldsValue({
        title: defaultValues?.title ?? '',
        description: defaultValues?.description ?? '',
        status: defaultValues?.status ?? STATUS_TYPES.DRAFT,
        permissions:
          defaultValues?.permissions?.map((value) => ({
            label: value,
            value
          })) ?? [],
        config: {
          key: defaultValues?.moduleData?.config?.key ?? '',
          min:
            defaultValues?.moduleData?.config?.min ??
            initialValues?.config?.min,
          max:
            defaultValues?.moduleData?.config?.max ?? initialValues?.config?.max
        }
      });
    }
  }, [form, moduleId, formType, defaultValues, form]);

  const handleSubmit = ({ config, permissions, ...rest }) => {
    const key = MODULE_KEYS[type];
    if (!key) return;

    const { key: keyField, ...restConfig } = config ?? {};

    const payload = {
      ...(!isEdit && {
        type,
        order: order + 1
      }),
      permissions: permissions?.map(({ value }) => value),
      ...rest,
      [key]: {
        config: {
          ...restConfig,
          ...(!isEdit && { key: keyField })
        }
      }
    };

    addEditModule({
      variables: { data: payload, id: isEdit ? moduleId : pageId }
    }).then(
      ({
        data: {
          addUpdatedPageModule: { pageModule }
        }
      }) => {
        onSuccess(pageModule);
      }
    );
  };

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={handleSubmit}
      initialValues={initialValues}
    >
      <ModuleFields showDescription={false} />
      <Space className="w-full" direction="vertical">
        <Space className="w-full" direction="vertical">
          <Typography.Text>{CONFIG_TITLE[type]}</Typography.Text>
          <div>
            <Form.Item label="Key" name={['config', 'key']}>
              <KeyInput
                disabled={isEdit}
                readOnly={isEdit}
                placeholder="Enter key"
              />
            </Form.Item>
            <Form.Item label="Min amount" name={['config', 'min']}>
              <CurrencyInput
                placeholder="Enter minimum amount"
                onChange={() => {
                  form.validateFields([['config', 'max']]);
                }}
              />
            </Form.Item>
            <Form.Item
              label="Max amount"
              name={['config', 'max']}
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    const minAmount = getFieldValue(['config', 'min']);
                    if (Number(minAmount) >= Number(value))
                      return Promise.reject(
                        new Error(`Amount should be grater than ${minAmount}`)
                      );
                    return Promise.resolve();
                  }
                })
              ]}
            >
              <CurrencyInput placeholder="Enter maximum amount" />
            </Form.Item>
          </div>
        </Space>
      </Space>
      <div className="d-flex button-section">
        <Space>
          <Form.Item>
            <Button
              type="text"
              htmlType="submit"
              className="text-btn mr-8"
              size="middle"
              disabled={loading}
              loading={loading}
            >
              Save
            </Button>
          </Form.Item>

          <Form.Item>
            <Button
              disabled={loading}
              onClick={onCancel}
              type="text"
              className="text-btn2"
            >
              Cancel
            </Button>
          </Form.Item>
        </Space>
      </div>
    </Form>
  );
};

export default DonationForm;
