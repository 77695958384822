import { UploadOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Form, Input, Space, Upload } from 'antd';
import { isArray } from 'lodash';
import React, { useEffect, useState } from 'react';
import { ASSET_CATEGORY, MODULES, ROUTES } from '../../../common/constants';
import {
  fileUpload,
  formValidatorRules,
  getBase64
} from '../../../common/utils';
import LoaderComponent from '../../../components/LoaderComponent';
import PageHeader from '../../../components/PageHeader';
import PreviewModal from '../../../components/PreviewModal';
import ProgressBar from '../../../components/ProgressBar';
import history from '../../../historyData';
import { CREATE_ASSET, UPDATE_ASSET } from '../graphql/Mutations';
import { GET_ASSET, GET_UPLOAD_SIGNED_URL } from '../graphql/Queries';

const { TextArea } = Input;

const AddEditAudio = (props) => {
  const { location } = props;
  const { audioId, isUpdate } = location?.state;
  const [audioData, setAudioData] = useState({});
  const [audioLoading, setAudioLoading] = useState(true);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewAudio, setPreviewAudio] = useState();
  const [previewTitle, setPreviewTitle] = useState();
  const [buttonLoading, setButtonLoading] = useState(false);
  const [audioProgress, setAudioProgress] = useState(undefined);
  const [fetchAudio] = useLazyQuery(GET_ASSET, {
    variables: { where: { id: audioId } },
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      setAudioLoading(true);
      setAudioData(res?.asset);
      setAudioLoading(false);
    },
    onError: () => {
      setAudioLoading(false);
    }
  });

  useEffect(() => {
    if (audioId) {
      fetchAudio();
    } else {
      setAudioLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioId]);

  const [getSignedUrl] = useLazyQuery(GET_UPLOAD_SIGNED_URL);
  const [createAssetMutate] = useMutation(CREATE_ASSET, {
    onError() {
      setButtonLoading(false);
    }
  });
  const [updateAssetMutate] = useMutation(UPDATE_ASSET, {
    onError() {
      setButtonLoading(false);
    }
  });

  const handleCancel = () => {
    history?.replace(ROUTES?.AUDIO);
  };

  const handlePreview = async (file) => {
    let preview;
    if (!file?.url && !file?.preview) {
      preview = await getBase64(file?.originFileObj);
    }
    setPreviewVisible(true);
    setPreviewTitle(
      file?.name ||
        file?.label ||
        file?.url?.substring(file?.url?.lastIndexOf('/') + 1)
    );
    setPreviewAudio(file?.url || preview);
  };

  const onFinish = async (values) => {
    setButtonLoading(true);
    const { audio } = values;
    try {
      const textData = {
        title: values?.title,
        description: values?.description
      };
      let key = null;
      let contentType = null;
      if (audio?.length > 0) {
        setAudioProgress(0);
        const audioFile = audio?.[0]?.originFileObj;
        const fileName = audio?.[0]?.name;
        contentType = audio?.[0]?.type;
        const getSignedPutUrlResult = await getSignedUrl({
          variables: {
            data: {
              fileName: fileName?.replace(/ /g, '_'),
              contentType,
              assetType: ASSET_CATEGORY?.AUDIO
            }
          }
        });
        if (getSignedPutUrlResult?.data?.getAssetUploadSignedUrl) {
          await fileUpload(
            getSignedPutUrlResult?.data?.getAssetUploadSignedUrl?.signedUrl,
            audioFile,
            setAudioProgress
          );
          key = getSignedPutUrlResult?.data?.getAssetUploadSignedUrl?.key;
        }
      }
      const media = {
        ...textData,
        categoryKey: ASSET_CATEGORY?.AUDIO,
        contentType,
        key
      };
      if (!key) {
        delete media?.key;
      }
      if (isUpdate) {
        const response = await updateAssetMutate({
          variables: {
            where: { id: audioId },
            data: {
              ...media
            }
          }
        });
        if (response?.data?.updateAsset) {
          setButtonLoading(false);
          history?.replace(ROUTES?.AUDIO);
        }
      } else {
        const response = await createAssetMutate({
          variables: {
            data: {
              ...media
            }
          }
        });
        if (response?.data?.createAsset) {
          history?.replace(ROUTES?.AUDIO);
          setAudioProgress(undefined);
          setButtonLoading(false);
        }
      }
    } catch (error) {
      setAudioProgress(undefined);
      setButtonLoading(false);
      return error;
    }
  };

  const initialValues = {
    ...audioData
  };

  if (audioLoading) {
    return <LoaderComponent />;
  }
  return (
    <>
      <PreviewModal
        previewData={previewAudio}
        previewTitle={previewTitle}
        previewVisible={previewVisible}
        setPreviewVisible={setPreviewVisible}
        previewType={ASSET_CATEGORY?.AUDIO}
      />
      <PageHeader menu={MODULES?.ASSETS} />
      <div className="page-wrapper">
        <div className="page-wrapper-body">
          <Form
            initialValues={initialValues || { isActive: true }}
            name="create-asset"
            layout="vertical"
            className="add-edit-form"
            onFinish={onFinish}
          >
            <Form.Item
              name="title"
              label="Title"
              required
              rules={[
                formValidatorRules?.required('Please enter title!'),
                formValidatorRules?.maxLength(200, 'Max 200 characters allowed')
              ]}
            >
              <Input placeholder="Enter title" disabled={buttonLoading} />
            </Form.Item>

            <Form.Item name="description" label="Description">
              <TextArea
                rows={2}
                placeholder="Enter description"
                disabled={buttonLoading}
              />
            </Form.Item>

            <Form.Item
              name="audio"
              label="Audio"
              rules={[{ required: !isUpdate, message: 'Please select audio!' }]}
              getValueFromEvent={(e) => {
                if (isArray(e)) {
                  return e;
                }
                return e?.fileList;
              }}
              valuePropName="fileList"
            >
              <Upload
                maxCount={1}
                disabled={buttonLoading}
                onPreview={handlePreview}
                accept=".mp3"
                beforeUpload={() => false}
              >
                <Space>
                  <Button icon={<UploadOutlined />}>Click to Upload</Button>
                  <span>(.mp3)</span>
                </Space>
              </Upload>
            </Form.Item>

            {audioProgress >= 0 && (
              <Form.Item>
                <ProgressBar progress={audioProgress} />
              </Form.Item>
            )}

            <div className="d-flex button-section">
              <Space>
                <Form.Item>
                  <Button
                    loading={buttonLoading}
                    type="text"
                    htmlType="submit"
                    className="text-btn mr-8"
                    size="middle"
                  >
                    Save
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button
                    type="text"
                    className="text-btn2"
                    disabled={buttonLoading}
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                </Form.Item>
              </Space>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default AddEditAudio;
