import { Button, ConfigProvider, Input } from 'antd';
import React from 'react';
import WebStyle from '../../../../styles/style-web.json';

function NewsLetter({
  title,
  description,
  background = 'bg-blue-2',
  darkBackground,
  responsive
}) {
  return (
    <ConfigProvider theme={WebStyle}>
      <section
        className={`newsletter-section  ${background} ${
          responsive ? 'responsive-view' : ''
        }`}
      >
        <div className="container">
          <div className="newsletter-wrapper text-center ">
            {title && (
              <div className={`title ${darkBackground && 'text-n-0'}`}>
                {title}
              </div>
            )}
            {description && (
              <p className={`description ${darkBackground && 'text-n-0'}`}>
                {description}
              </p>
            )}

            <div className="subscribe-group">
              <div className="form-group">
                <Input placeholder="Enter email" />
              </div>
              <Button
                size="large"
                className={`cs-button  ${
                  darkBackground
                    ? ' bg-n-0 text-n-900 b-n-0 hbg-n-300 hc-n-900 hb-n-300'
                    : 'bg-n-900 text-n-100 hbg-black-80 hc-n-100 hb-black-80'
                } `}
              >
                Subscribe
              </Button>
            </div>
          </div>
          {/* after subscribe thank you html start here */}
          {/* <div className="newsletter-wrapper thank-you">
          <div className={`title text-center ${darkBackground && 'text-n-0'}`}>
            Thank you for Subscribing
          </div>

        </div> */}
          {/* after subscribe thank you html end here */}
        </div>
      </section>
    </ConfigProvider>
  );
}
export default NewsLetter;
