import { Button } from 'antd';
import React from 'react';

function CtaBanner({
  background = 'bg-transparent',
  ctaBackgroundImage,
  title,
  descriptions,
  ctaBackgroundColor,
  ctaBackgroundDark,
  cta,
  responsive
}) {
  return (
    <section
      className={`ctaBanner-section ${background} ${
        responsive ? 'responsive-view' : ''
      }`}
    >
      <div className="container-rnp">
        <div className="container-marketing">
          <div
            className={`cta-bnnner-block ${
              // eslint-disable-next-line no-nested-ternary
              ctaBackgroundImage
                ? 'ctabannerbackground'
                : (ctaBackgroundColor &&
                    `${ctaBackgroundColor} ctabannerbackgroundcolor`) ||
                  'ctabannerbackground-none'
            }`}
          >
            {ctaBackgroundImage && (
              <img src={ctaBackgroundImage} fill objectFit="cover" alt="" />
            )}

            <div className="cta-content">
              {title && (
                <div className={`title ${ctaBackgroundDark && 'text-n-0'}`}>
                  {title}
                </div>
              )}
              {descriptions && (
                <p className={`description ${ctaBackgroundDark && 'text-n-0'}`}>
                  {descriptions}
                </p>
              )}
              {cta && (
                <Button
                  size="large"
                  className={`cs-button m-auto ${
                    ctaBackgroundDark
                      ? 'bg-n-0 text-n-900 b-n-0 hbg-transparent hc-n-100'
                      : 'bg-n-900 text-n-100 hbg-transparent hc-n-900 hb-n-900'
                  }`}
                >
                  {cta}
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default CtaBanner;
