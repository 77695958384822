import { Avatar, Badge, Button, ConfigProvider } from 'antd';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { CaretRight, Export, HeartStraight, Newspaper } from 'phosphor-react';
import React, { useEffect, useRef, useState } from 'react';
import VideoImg from '../../../assets/images/video-img.png';
import WebStyle from '../../../styles/style-web.json';
import TagCarousel from '../tagModules/TagCarousel';

dayjs?.extend(duration);
dayjs.extend(utc);
dayjs.extend(timezone);

function LiveVideoInfo({
  category,
  categoryTagStyle = 'bg-yellow-2 text-n-1000',
  title,
  speakers,
  tags = [],
  description,
  responsive,
  primaryAction,
  timer
}) {
  const [showTimer, setShowTimer] = useState(false);
  const [date, setDate] = useState(null);
  const interval = useRef(null);

  const updateCountdown = (dateTime) => {
    const startDate = dayjs(dateTime)?.tz('America/Chicago', true);
    const now = new Date();

    const timeDifference = startDate - now;

    if (timeDifference <= 0) {
      setShowTimer(false);
      clearInterval(interval?.current);
    } else {
      const days = Math?.floor(timeDifference / (1000 * 60 * 60 * 24));
      const hours = Math?.floor(
        (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math?.floor(
        (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
      );

      setDate(
        `${days > 0 ? `${days} days` : ''}  ${
          hours > 0 ? `${hours} hours` : ''
        } ${
          minutes >= 0
            ? `${timeDifference < 60000 ? `< 1` : minutes} minutes`
            : ''
        }`
      );
    }
  };

  useEffect(() => {
    if (timer?.startDate) {
      setShowTimer(
        dayjs(timer?.startDate)?.tz('America/Chicago', true)?.isAfter(dayjs())
      );
      updateCountdown(timer?.startDate);
      interval.current = setInterval(
        () => updateCountdown(timer?.startDate),
        1000
      );
    }
    // Clean up the interval when the component unmounts
    return () => {
      if (timer?.startDate) {
        clearInterval(interval?.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timer]);

  return (
    <ConfigProvider theme={WebStyle}>
      <section
        className={`video-info-section ${responsive ? 'responsive-view' : ' '}`}
      >
        <div>
          <img src={VideoImg} alt="video dummy img" />
        </div>

        <div className="container">
          {(primaryAction?.title || timer?.startDate) && (
            <div className="video-event">
              {date && showTimer && (
                <div className="event-dec">
                  <div className="title">Livestream begins in</div>
                  <p>{date}</p>
                </div>
              )}
              {primaryAction?.title && (
                <Button
                  className="cs-button bg-n-900 text-n-100 hbg-transparent hc-n-900 hb-n-900"
                  target="_blank"
                >
                  {primaryAction?.title || ''}
                </Button>
              )}
            </div>
          )}
          <div className="video-info-wrapper">
            <div className="videoinfo-title">
              {category ? (
                <Badge className={`badge-xs  ${categoryTagStyle}`}>
                  {category}
                </Badge>
              ) : null}

              {title ? <div className="display-h4">{title}</div> : null}
            </div>
            <div className="video-button-wrapper">
              <Button
                className="b-0 bg-n-400 text-n-900 text-small videoaction"
                icon={<Export size={24} weight="light" />}
              >
                Share
              </Button>
              <Button
                className="b-0 bg-n-400 text-n-900 text-small videoaction"
                icon={<Newspaper size={24} weight="light" />}
              >
                Resources
              </Button>
              <Button
                className="b-0 bg-n-400 text-n-900 text-small videoaction"
                icon={<HeartStraight size={24} weight="light" />}
              >
                Donate
              </Button>
            </div>
            <div className="video-avatar-group">
              {speakers?.map((speaker) => (
                <div
                  key={speaker?.id}
                  className="item d-flex align-center gap-8 flex-shrink-0"
                >
                  <Avatar
                    className="overflow-hidden"
                    size="small"
                    src={speaker?.image}
                  />
                  <div className="text-small-bold">{`${speaker?.firstName} ${speaker?.lastName}`}</div>
                </div>
              ))}
            </div>

            {tags?.length > 0 ? (
              <div className="video-info-tags">
                <TagCarousel
                  className="text-small tag-s bg-n-400 b-0 text-n-900 mr-4"
                  data={tags}
                  icon={<CaretRight size={16} />}
                />
              </div>
            ) : null}
            {description ? <p className="paragraph">{description}</p> : null}
          </div>
        </div>
      </section>
    </ConfigProvider>
  );
}
export default LiveVideoInfo;
