import { useMutation, useQuery } from '@apollo/client';
import { Button, Select, Space, Tooltip, Typography } from 'antd';
import { ArrowClockwise } from 'phosphor-react';
import React, { useEffect, useState } from 'react';
import {
  CLOUD_FRONT_CACHE_KEYS,
  MODULES,
  ROUTES
} from '../../common/constants';
import EmptyStatePage from '../../components/EmptyStatePage';
import PageHeader from '../../components/PageHeader';
import { MENU_POSITIONS, MENU_POSITIONS_OPTIONS } from './AddEditMenu';
import Table from './components/Table';
import { INVALIDATE_CLOUD_FRONT_CACHE } from './graphql/Mutations';
import { GET_MENUS } from './graphql/Queries';

const removeNestedItem = (data, parentIds, id) => {
  if (!parentIds.length) return data.filter((item) => item.id !== id);
  const parentId = parentIds.splice(0, 1)[0];

  return data.map((item) => {
    if (item.id === parentId) {
      return {
        ...item,
        menuItems: removeNestedItem(item.menuItems, parentIds, id)
      };
    }
    return item;
  });
};

const moveNestedItem = (data, parentIds, newMenuItems) => {
  if (!parentIds.length) return newMenuItems;
  const parentId = parentIds.splice(0, 1)[0];
  return data.map((item) => {
    if (item.id === parentId) {
      return {
        ...item,
        menuItems: moveNestedItem(item.menuItems, parentIds, newMenuItems)
      };
    }
    return item;
  });
};

const Menus = ({ history }) => {
  const [position, setPosition] = useState(MENU_POSITIONS.HEADER);
  const [menus, setMenus] = useState([]);

  const [invalidateCache, { loading: isInvalidating }] = useMutation(
    INVALIDATE_CLOUD_FRONT_CACHE,
    {
      variables: { key: CLOUD_FRONT_CACHE_KEYS.MENU }
    }
  );

  const { loading, refetch, data } = useQuery(GET_MENUS, {
    fetchPolicy: 'network-only',
    onCompleted: ({ menusAdmin: { menus: values = [] } = {} }) => {
      setMenus(values?.filter((menu) => menu?.position === position));
    }
  });

  useEffect(() => {
    const allMenus = data?.menusAdmin?.menus ?? [];
    if (allMenus) {
      setMenus(allMenus?.filter((menu) => menu?.position === position));
    }
  }, [data, position]);

  const handleShowModal = () => {
    history.push(`${ROUTES?.MENUS}/add?position=${position}`);
  };

  const removeItem = (parentIds, id) => {
    setMenus((prev) => removeNestedItem(prev, [...parentIds], id));
  };

  const moveItem = (parentIds, menuItems) => {
    setMenus((prev) => moveNestedItem(prev, [...parentIds], menuItems));
  };

  const handlePositionChange = (value) => setPosition(value);
  const handleMoveSuccess = () => refetch();

  return (
    <>
      <PageHeader menu={MODULES?.MENUS} handleShowModal={handleShowModal} />
      <div className="assets-filter-wrapper">
        <div className="asset-filter-left" />
        <div className="asset-filter-right">
          <div className="asset-filter">
            <Space size="small" wrap>
              <Typography.Text className="sort-by-title" type="secondary">
                Position
              </Typography.Text>
              <Select
                value={position}
                options={MENU_POSITIONS_OPTIONS}
                onChange={handlePositionChange}
              />
              <Tooltip title="Revalidate cache" placement="left">
                <Button
                  className="text-btn d-flex align-center justify-center"
                  type="text"
                  icon={<ArrowClockwise size={24} />}
                  onClick={invalidateCache}
                  loading={isInvalidating}
                />
              </Tooltip>
            </Space>
          </div>
        </div>
      </div>
      <div className="ant-body-scroll assets-wrapper">
        {!loading && menus.length <= 0 ? (
          <EmptyStatePage />
        ) : (
          <div className="listview nested-table">
            <Table
              loading={loading}
              data={menus}
              removeItem={removeItem}
              moveItem={moveItem}
              onMoveSuccess={handleMoveSuccess}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default Menus;
