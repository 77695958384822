import { Carousel } from 'antd';
import React from 'react';
import FeaturedVideoCard from './FeaturedVideoCard';

function FeaturedCarousel({
  responsive,
  data = [],
  canterMode = true,
  sectionTitle,
  showIndicator,
  showCTA
}) {
  const settings = {
    dots: !!responsive && !!showIndicator,
    arrows: !responsive,
    infinite: true,
    centerMode: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: false,
    draggable: true,
    loop: true,
    // eslint-disable-next-line no-nested-ternary
    centerPadding: canterMode ? (responsive ? '0' : '9%') : 0,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
          centerPadding: '0',
          arrows: false,
          variableHeight: true,
          fade: true
        }
      }
    ]
  };
  return (
    <section
      className={`featuredcarousel ${canterMode ? '' : 'centerfalse'} ${
        responsive ? 'responsive-view' : ''
      }`}
    >
      {sectionTitle ? (
        <div className="display-h4 mb-8">{sectionTitle}</div>
      ) : null}

      <Carousel {...settings}>
        {data?.map((item) => {
          return (
            <FeaturedVideoCard
              key={item?.id}
              tagName={item?.tagName}
              tagBackground={item?.tagBackground}
              heading={item?.heading}
              subHeading={item?.subHeading}
              date={item?.date}
              description={item?.description}
              tagData={item?.tagData}
              src={item?.src}
              blurHash={item.blurHash}
              showCTA={showCTA}
            />
          );
        })}
      </Carousel>
    </section>
  );
}
export default FeaturedCarousel;
