import { useMutation } from '@apollo/client';
import { Button, Select, Tag, Tooltip } from 'antd';
import { ArrowClockwise, CaretRight, Eye, EyeSlash } from 'phosphor-react';
import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  CLOUD_FRONT_CACHE_KEYS,
  MODULES,
  ORDER,
  ROUTES,
  TAGS_SORT_FIELD
} from '../../../common/constants';
import FilterBar, { useFilterBar } from '../../../components/FilterBar';
import PageHeader from '../../../components/PageHeader';
import PageList from '../../../components/PageList';
import { INVALIDATE_CLOUD_FRONT_CACHE } from '../../menus/graphql/Mutations';
import { UPDATE_TAG } from './graphql/Mutations';
import { GET_TAGS } from './graphql/Queries';

const variablesSelector = ({
  limit,
  offset,
  search,
  sortField,
  sortOrder,
  isActive
}) => ({
  filter: {
    skip: offset,
    limit,
    search
  },
  sort: {
    sortOn: sortField,
    sortBy: sortOrder
  },
  where: {
    isActive
  }
});

const dataSelector = ({ tagsAdmin }) => ({
  data: tagsAdmin?.tags ?? [],
  count: tagsAdmin?.count ?? 0
});

const TagCard = ({ name, id, isActive }) => {
  const [disabled, setDisabled] = useState(!isActive);
  const [updateTag, { loading }] = useMutation(UPDATE_TAG);

  const history = useHistory();
  const handleActiveDeActive = (e) => {
    e.stopPropagation();

    updateTag({
      variables: {
        data: {
          isActive: disabled
        },
        key: id
      }
    })
      .then(() => {
        setDisabled((prev) => !prev);
      })
      .catch();
  };

  const handleTagClick = () => {
    if (disabled) return;
    history.push(`${ROUTES?.TAGS}/${id}/edit`);
  };

  return (
    <div onClick={handleTagClick}>
      <Tag
        className={`text-md tag-s bg-n-400 b-0 text-n-900 mr-4 tag-small ${
          disabled ? 'disable' : 'pointer'
        } `}
        size="large"
      >
        <Button
          onClick={handleActiveDeActive}
          type="button"
          className="eye-icon"
          loading={loading}
          icon={disabled ? <EyeSlash /> : <Eye />}
        />
        <span className="tag-text">{name}</span>
        {!disabled && <CaretRight className="caret-icon" size={16} />}
      </Tag>
    </div>
  );
};
const ACTIVE = 'ACTIVE';
const INACTIVE = 'INACTIVE';
const TAG_VISIBILITY_OPTIONS = [
  { label: 'Active', value: ACTIVE },
  { label: 'Inactive', value: INACTIVE }
];

const Tags = ({ history }) => {
  const [type, setType] = useState(null);
  const [invalidateCache, { loading: isInvalidating }] = useMutation(
    INVALIDATE_CLOUD_FRONT_CACHE,
    {
      variables: { key: CLOUD_FRONT_CACHE_KEYS.OG_IMAGES }
    }
  );
  const filterProps = useFilterBar({
    defaultSortOptions: {
      options: [
        ...TAGS_SORT_FIELD,
        { name: 'Active/Inactive', value: 'isActive' }
      ],
      order: ORDER[1].value,
      field: TAGS_SORT_FIELD[0].value
    }
  });
  const handleShowModal = () => {
    history.push(`${ROUTES?.TAGS}/add`);
  };

  const handleVisibilityChange = (value) => {
    setType(value);
  };

  const filters = useMemo(
    () => ({
      ...filterProps.filters,
      isActive: { [ACTIVE]: true, [INACTIVE]: false }[type] ?? null
    }),
    [filterProps.filters, type]
  );

  return (
    <>
      <PageHeader menu={MODULES?.LABELS} handleShowModal={handleShowModal} />
      <FilterBar
        {...filterProps}
        SearchProps={{ placeholder: 'Search tags' }}
        show={{ listModes: false }}
      >
        <Select
          allowClear
          value={type}
          dropdownMatchSelectWidth={false}
          placeholder="Select visibility"
          options={TAG_VISIBILITY_OPTIONS}
          onChange={handleVisibilityChange}
        />
        <Tooltip title="Revalidate OG images" placement="left">
          <Button
            className="text-btn d-flex align-center justify-center"
            type="text"
            icon={<ArrowClockwise size={24} />}
            onClick={invalidateCache}
            loading={isInvalidating}
          />
        </Tooltip>
      </FilterBar>
      <PageList
        query={GET_TAGS}
        filters={filters}
        variablesSelector={variablesSelector}
        dataSelector={dataSelector}
        limit={200}
        keyField="key"
        GridProps={{
          renderItem: (props) => <TagCard id={props.key} {...props} />,
          breakPoints: 'auto',
          colSpacing: 4,
          rowSpacing: 8
        }}
      />
    </>
  );
};

export default Tags;
