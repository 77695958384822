import { Avatar, Space } from 'antd';
import React, { useContext } from 'react';
import { AppContext } from '../../../AppContext';

const UserProfile = (props) => {
  const { collapsed } = props;
  const { getCurrentUser } = useContext(AppContext);
  const { firstName = '', lastName = '' } = getCurrentUser() || {};

  return (
    <Space className="pointer">
      <Avatar
        alt="Avatar"
        size="small"
      >{`${firstName?.[0]}${lastName?.[0]}`}</Avatar>
      <span className={collapsed ? 'd-hide' : ''}>{`${firstName} ${
        lastName && lastName
      }`}</span>
    </Space>
  );
};

export default UserProfile;
