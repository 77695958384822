import { gql } from '@apollo/client';

export const LOGIN = gql`
  mutation loginAdmin($data: LoginInput!) {
    loginAdmin(data: $data) {
      accessToken
      refreshToken
      user {
        id
        email
        profileImage
        firstName
        lastName
      }
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation forgotPassword($email: String!) {
    forgotPassword(data: { email: $email }) {
      message
    }
  }
`;

export const UPDATE_PASSWORD = gql`
  mutation updatePassword($data: UpdatePasswordInput!) {
    updatePassword(data: $data) {
      message
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation changePasswordAdmin($data: ChangePasswordInput!) {
    changePasswordAdmin(data: $data) {
      message
    }
  }
`;

export const LOGOUT_USER = gql`
  mutation logout {
    logoutAdmin {
      message
    }
  }
`;

export const REFRESH_TOKEN = gql`
  mutation refreshToken($data: RefreshTokenInput!) {
    refreshToken(data: $data) {
      accessToken
      user {
        id
        email
        profileImage
        firstName
        lastName
      }
    }
  }
`;

export const VERIFY_TOKEN = gql`
  mutation verifyResetToken($data: ResetTokenInput!) {
    verifyResetToken(data: $data) {
      message
    }
  }
`;

export const UPDATE_CURRENT_USER = gql`
  mutation updateCurrentUser($data: UpdateCurrentUserInput!) {
    updateCurrentUser(data: $data) {
      message
    }
  }
`;

export const GET_PROFILE_IMAGE_SIGNED_URL = gql`
  query getProfileImageUploadSignedUrl(
    $data: GetProfileImageUploadSignedUrlInput!
  ) {
    getProfileImageUploadSignedUrl(data: $data) {
      signedUrl
      key
    }
  }
`;
