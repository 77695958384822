import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Badge, Button, Modal, Select, Space, Tooltip } from 'antd';
import moment from 'moment';
import {
  ArrowClockwise,
  ArrowSquareOut,
  FileArrowUp,
  FileDotted
} from 'phosphor-react';
import React, { useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  FULL_DATE_TIME_FORMAT,
  LIST_TYPES,
  MODULES,
  ORDER,
  PAGES_SORT_FIELD,
  PAGE_TYPE_LABELS,
  ROUTES,
  STATUS_TYPES,
  WEB_ROUTES
} from '../../common/constants';
import FilterBar, { useFilterBar } from '../../components/FilterBar';
import PageHeader from '../../components/PageHeader';
import PageList from '../../components/PageList';
import {
  DELETE_PAGE,
  FORCE_PURGE_PAGE_CACHE,
  UPDATE_PAGE_STATUS
} from './graphql/Mutations';
import { GET_PAGES } from './graphql/Queries';

const variablesSelector = ({
  limit,
  offset,
  search,
  sortField,
  sortOrder,
  isDefault
}) => ({
  filter: {
    limit,
    skip: offset,
    search,
    isDefault
  },
  sort: {
    sortOn: sortField,
    sortBy: sortOrder
  }
});

const dataSelector = ({ pagesAdmin }) => ({
  data: pagesAdmin?.pages || [],
  count: pagesAdmin?.count || 0
});

const PAGE_TYPE_OPTIONS = [
  { label: 'Custom', value: false },
  { label: 'Default', value: true }
];

const Action = ({
  status,
  id,
  refresh,
  removeItem,
  isDefault,
  allowDelete,
  slug,
  type
}) => {
  const [updatePageStatus] = useMutation(UPDATE_PAGE_STATUS);
  const [deletePage] = useMutation(DELETE_PAGE);
  const [purgePageCache, { loading: isPurging }] = useMutation(
    FORCE_PURGE_PAGE_CACHE
  );
  const newStatus =
    status === STATUS_TYPES.DRAFT ? STATUS_TYPES.PUBLISHED : STATUS_TYPES.DRAFT;

  const title = {
    [STATUS_TYPES.PUBLISHED]: 'Publish this page',
    [STATUS_TYPES.DRAFT]: 'Draft this page'
  }[newStatus];

  const Icon = {
    [STATUS_TYPES.PUBLISHED]: FileArrowUp,
    [STATUS_TYPES.DRAFT]: FileDotted
  }[newStatus];

  const isDeleteDisable =
    (isDefault || !allowDelete) && status === STATUS_TYPES.PUBLISHED;

  const handleDelete = () => {
    if (!isDeleteDisable)
      Modal.confirm({
        title: 'Are you sure, you want to delete this page?',
        centered: true,
        okText: 'Yes',
        cancelText: 'No',
        onOk() {
          deletePage({
            variables: { id }
          })
            .then(({ errors }) => {
              if (removeItem && !errors) {
                removeItem(id);
              }
            })
            .catch();
        }
      });
  };

  const handleUpdateStatus = (modalTitle) => {
    Modal.confirm({
      title: modalTitle,
      centered: true,
      okText: 'Yes',
      cancelText: 'No',
      okType: 'primary',

      onOk() {
        updatePageStatus({
          variables: { id, status: newStatus }
        })
          .then(({ errors }) => {
            if (refresh && !errors) {
              refresh();
            }
          })
          .catch();
      }
    });
  };

  const handleEdit = (e) => {
    if (status === STATUS_TYPES.PUBLISHED) e.preventDefault();
  };

  const forcePurge = () => {
    if (slug && type) {
      purgePageCache({
        variables: {
          slug,
          type
        }
      });
    }
  };

  const url = `${process.env.REACT_APP_WEB_URL}${
    WEB_ROUTES[type] ?? ''
  }/${slug}`;

  return (
    <div className="d-flex align-center">
      {!isDefault && (
        <Tooltip title="Preview In New Tab">
          <Button
            className="text-btn d-flex align-center justify-center"
            type="text"
            icon={<ArrowSquareOut size={22} />}
            disabled={status !== STATUS_TYPES.PUBLISHED}
            // eslint-disable-next-line no-undef
            onClick={() => window?.open(url)}
          />
        </Tooltip>
      )}

      <Tooltip title={title}>
        <Button
          className="text-btn d-flex align-center justify-center"
          type="text"
          onClick={() => {
            handleUpdateStatus(
              `Are you sure, you want to ${title.toLocaleLowerCase()}?`
            );
          }}
        >
          <Icon size={22} />
        </Button>
      </Tooltip>

      <Tooltip title="Force purge page cache">
        <Button
          className="text-btn d-flex align-center justify-center"
          type="text"
          icon={<ArrowClockwise size={22} />}
          onClick={forcePurge}
          loading={isPurging}
          disabled={isPurging || !slug}
        />
      </Tooltip>

      <Tooltip
        title={
          status === STATUS_TYPES.PUBLISHED
            ? 'Draft this page to edit'
            : 'Edit page'
        }
      >
        <Link
          to={`${ROUTES?.PAGES_MODULE}/${id}/edit`}
          disabled={status === STATUS_TYPES.PUBLISHED}
          className="text-btn"
          type="text"
          onClick={handleEdit}
        >
          <EditOutlined />
        </Link>
      </Tooltip>

      <Tooltip
        title={isDeleteDisable ? 'You can not delete this page' : 'Delete page'}
      >
        <Button
          disabled={isDeleteDisable}
          className="text-btn"
          type="text"
          onClick={handleDelete}
        >
          <DeleteOutlined />
        </Button>
      </Tooltip>
    </div>
  );
};

export const Status = ({ status, type, slug, isDefault }) => {
  const url = `${process.env.REACT_APP_WEB_URL}${
    WEB_ROUTES[type] ?? ''
  }/${slug}`;
  return (
    <div>
      {status === STATUS_TYPES.DRAFT && (
        <Badge className="status" count={status} color="#5E5957" />
      )}
      {status === STATUS_TYPES.PUBLISHED && (
        <>
          {isDefault ? (
            <Badge className="status" count={status} color="#52c41a" />
          ) : (
            <a
              target="_blank"
              className="pointer"
              href={url ?? '#'}
              rel="noreferrer"
            >
              <Badge className="status" count={status} color="#52c41a" />
            </a>
          )}
        </>
      )}
      {status === STATUS_TYPES.UNPUBLISHED && (
        <Badge className="status" count={status} />
      )}
    </div>
  );
};

const tableColumns = ({ refresh, removeItem }, isDefault) => [
  {
    title: 'Name',
    dataIndex: 'title',
    key: 'title',
    width: '12%'
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    width: '12%',
    render: (text, record) => (
      <Space>
        <span>{PAGE_TYPE_LABELS[text]}</span>
        <span>
          <b>{record?.isDefault ? 'Default' : null}</b>
        </span>
      </Space>
    )
  },
  ...(!isDefault
    ? [
        {
          title: 'Slug',
          dataIndex: 'slug',
          key: 'slug'
        }
      ]
    : []),
  {
    title: 'Last modified',
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    width: '22%',
    render: (_, record) => (
      <span>{moment(record?.updatedAt).format(FULL_DATE_TIME_FORMAT)}</span>
    )
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    width: '22%',
    render: (text, record) => <Status status={text} {...record} />
  },
  {
    title: 'Actions',
    dataIndex: 'id',
    render: (_, record) => (
      <Action {...record} refresh={refresh} removeItem={removeItem} />
    )
  }
];

function Pages({ history }) {
  const [isDefault, setIsDefault] = useState(false);
  const [purgeAllPageCache, { loading: isPurging }] = useMutation(
    FORCE_PURGE_PAGE_CACHE
  );

  const columns = useCallback((props) => tableColumns(props, isDefault), [
    isDefault
  ]);

  const filterProps = useFilterBar({
    defaultSortOptions: {
      options: PAGES_SORT_FIELD,
      order: ORDER[1].value,
      field: PAGES_SORT_FIELD[0].value
    }
  });

  const filters = useMemo(() => ({ ...filterProps.filters, isDefault }), [
    filterProps.filters,
    isDefault
  ]);

  const handleShowModal = () => {
    history?.push(`${ROUTES?.PAGES_MODULE}/add`, { isUpdate: false });
  };

  const handleIsDefaultChange = (val) => setIsDefault(val);

  const forcePurge = () => {
    purgeAllPageCache({
      variables: {
        all: true
      }
    });
  };

  return (
    <>
      <PageHeader menu={MODULES?.ASSETS} handleShowModal={handleShowModal} />
      <FilterBar
        {...filterProps}
        SearchProps={{ placeholder: 'Search Pages' }}
        show={{ listModes: false }}
      >
        <Select
          value={isDefault}
          options={PAGE_TYPE_OPTIONS}
          onChange={handleIsDefaultChange}
        />
        <Tooltip title="Force purge all page cache" placement="left">
          <Button
            className="text-btn d-flex align-center justify-center"
            type="text"
            icon={<ArrowClockwise size={24} />}
            onClick={forcePurge}
            loading={isPurging}
          />
        </Tooltip>
      </FilterBar>
      <PageList
        filters={filters}
        listMode={LIST_TYPES.TABLE}
        query={GET_PAGES}
        variablesSelector={variablesSelector}
        dataSelector={dataSelector}
        TableProps={{ columns }}
        limit={30}
      />
    </>
  );
}

export default Pages;
