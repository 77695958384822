import { useMutation } from '@apollo/client';
import { Button, Modal, Tooltip } from 'antd';
import { ArrowClockwise } from 'phosphor-react';
import React, { useRef } from 'react';
import {
  CLOUD_FRONT_CACHE_KEYS,
  MODULES,
  ORDER,
  ROUTES,
  TOPICS_SORT_FIELD
} from '../../../common/constants';
import FilterBar, { useFilterBar } from '../../../components/FilterBar';
import PageHeader from '../../../components/PageHeader';
import PageList from '../../../components/PageList';
import CategoryCard from '../../component/categoryModule/CategoryCard';
import { INVALIDATE_CLOUD_FRONT_CACHE } from '../../menus/graphql/Mutations';
import { DELETE_TOPIC } from './graphql/Mutations';
import { GET_TOPICS } from './graphql/Queries';

const variablesSelector = ({
  limit,
  offset,
  search,
  sortField,
  sortOrder
}) => ({
  filter: {
    skip: offset,
    limit,
    search
  },
  sort: {
    sortOn: sortField,
    sortBy: sortOrder
  }
});

const dataSelector = ({ topicsAdmin }) => ({
  data: topicsAdmin?.topics ?? [],
  count: topicsAdmin?.count ?? 0
});

const TopicCard = ({ name, primaryColor, id, onDelete }) => {
  return (
    <CategoryCard
      topic={name}
      grid
      backgroundColor={primaryColor}
      href={`${ROUTES?.TOPICS}/${id}/edit`}
      onDelete={() => onDelete(id)}
    />
  );
};

const Topics = ({ history }) => {
  const ref = useRef();

  const [invalidateCache, { loading: isInvalidating }] = useMutation(
    INVALIDATE_CLOUD_FRONT_CACHE,
    {
      variables: { key: CLOUD_FRONT_CACHE_KEYS.OG_IMAGES }
    }
  );

  const filterProps = useFilterBar({
    defaultSortOptions: {
      options: TOPICS_SORT_FIELD,
      order: ORDER[1].value,
      field: TOPICS_SORT_FIELD[0].value
    }
  });

  const [deleteTopic] = useMutation(DELETE_TOPIC);

  const handleShowModal = () => {
    history.push(`${ROUTES?.TOPICS}/add`);
  };

  const handleDelete = (id) => {
    Modal.confirm({
      title: 'Are you sure, you want to delete this topic?',
      centered: true,
      okText: 'Yes',
      cancelText: 'No',
      okType: 'primary',
      onOk() {
        deleteTopic({
          variables: { id }
        })
          .then(({ errors }) => {
            if (ref.current.removeItem && !errors) {
              ref.current.removeItem(id);
            }
          })
          .catch();
      }
    });
  };

  return (
    <>
      <PageHeader menu={MODULES?.LABELS} handleShowModal={handleShowModal} />
      <FilterBar
        {...filterProps}
        SearchProps={{ placeholder: 'Search topics' }}
        show={{ listModes: false }}
      >
        <Tooltip title="Revalidate OG images" placement="left">
          <Button
            className="text-btn d-flex align-center justify-center"
            type="text"
            icon={<ArrowClockwise size={24} />}
            onClick={invalidateCache}
            loading={isInvalidating}
          />
        </Tooltip>
      </FilterBar>
      <PageList
        ref={ref}
        filters={filterProps.filters}
        query={GET_TOPICS}
        variablesSelector={variablesSelector}
        dataSelector={dataSelector}
        limit={50}
        GridProps={{
          renderItem: (props) => (
            <TopicCard {...props} onDelete={handleDelete} />
          ),
          breakPoints: 'auto'
        }}
      />
    </>
  );
};

export default Topics;
