import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../../common/constants';
import AddEditTags from './AddEditTags';
import Tags from './Tags';

const TagsWrapper = () => {
  return (
    <Switch>
      <Route exact path={ROUTES?.TAGS} component={Tags} />
      <Route exact path={`${ROUTES?.TAGS}/add`} component={AddEditTags} />
      <Route
        exact
        path={`${ROUTES?.TAGS}/:tagId/edit`}
        component={AddEditTags}
      />
      <Route
        exact
        path={`${ROUTES?.TAGS}/:tagId`}
        render={() => <Redirect to={ROUTES?.TAGS} />}
      />
    </Switch>
  );
};

export default TagsWrapper;
