import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const GET_CURRENT_USER = gql`
  query currentUser {
    currentUser {
      id
      email
      profileImage
      firstName
      lastName
    }
  }
`;
