import { useQuery } from '@apollo/client';
import { Avatar } from 'antd';
import { isNumber } from 'lodash';
import moment from 'moment';
import { CaretRight } from 'phosphor-react';
import React, { createContext, useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  ALIGNMENTS,
  ASSET_CATEGORY,
  MODULE_TYPES,
  RATIOS,
  THEME_PICKER_COLORS,
  defaultDateFormat
} from '../../../common/constants';
import { getImageUrl } from '../../../common/utils';
import { GET_PAGE_DETAILS_FOR_PREVIEW } from '../graphql/Queries';

const ctx = createContext();

export const usePagePreview = () => useContext(ctx);

const secondsToMinute = (seconds) =>
  isNumber(seconds)
    ? `${Math.floor(seconds / 60)}:${Math.floor(seconds % 60)
        .toString()
        .padStart(2, 0)}`
    : null;

// Videos
const defaultVideoDataHandler = (data) => ({
  sectionHeading: data?.title,
  headerLink: data?.moduleData?.settings?.viewAll ? '/' : null,
  headerDescription: data?.description,
  separator: !!data?.moduleData?.settings?.separator,
  data:
    data?.moduleData?.config?.videos?.map(({ video, order }) => ({
      id: video?.id,
      defaultValue:
        data?.moduleData?.settings?.videoProgressBar &&
        Math.floor(Math.random() * 100) + 1,
      time: secondsToMinute(video?.video?.information?.durationInSeconds),
      collection: !!order && `part ${order}`,
      category: video?.topics?.[0]?.name ?? '',
      title: video?.title ?? '',
      description: video?.description ?? '',
      date: video?.createdAt
        ? moment(video?.createdAt).format(defaultDateFormat)
        : '',
      speaker: video?.authors
        ?.map(
          (author) =>
            [author?.firstName, author?.lastName].filter(Boolean).join(' ') ??
            ''
        )
        ?.join(', '),
      tags: video?.tags?.map(({ name }) => name) ?? [],
      tagBackground: video?.topics?.[0]?.primaryColor ?? 'bg-n-200',
      src: getImageUrl(video?.videoThumbnail?.url) ?? '',
      blurHash: video?.videoThumbnail?.blurhash
    })) || []
});
const getFeatureVideoData = (video) => ({
  id: video?.id,
  tagName: video?.topics?.[0]?.name ?? '',
  heading: video?.title ?? '',
  subHeading: video?.authors
    ?.map(
      (author) =>
        [author?.firstName, author?.lastName].filter(Boolean).join(' ') ?? ''
    )
    ?.join(', '),
  description: video?.description ?? '',
  tagData:
    video?.tags?.map(({ key, name }) => ({
      id: key,
      name
    })) ?? [],
  tagBackground: video?.topics?.[0]?.primaryColor ?? 'bg-n-200',
  src: video?.videoThumbnail?.url ?? '',
  blurHash: video?.videoThumbnail?.blurhash
});
const defaultFeatureVideoDataHandler = (data, multiple = true) => ({
  data: multiple
    ? data?.moduleData?.config?.videos.map(({ video }) =>
        getFeatureVideoData(video)
      )
    : [getFeatureVideoData(data?.moduleData?.config?.video)]
});

const defaultVideoPlayerDataHandler = (data) => ({
  title: data?.moduleData?.config?.video?.title,
  description: data?.moduleData?.config?.video?.description,
  category: data?.moduleData?.config?.video?.topics?.[0]?.name,
  categoryTagStyle:
    data?.moduleData?.config?.video?.topics?.[0]?.primaryColor ?? 'bg-n-200',
  tags:
    data?.moduleData?.config?.video?.tags?.map(({ key, name }) => ({
      id: key,
      name: name ?? ''
    })) ?? [],
  ...(data?.moduleData?.config?.video?.authors && {
    speakers: data?.moduleData?.config?.video?.authors?.map((speaker) => ({
      firstName: speaker?.firstName,
      lastName: speaker?.lastName,
      image: speaker?.image?.url
    }))
  }),
  primaryAction: data?.moduleData?.config?.primaryAction,
  timer: data?.moduleData?.config?.timer
});

const COLLECTIONS_ASPECT_RATIOS = {
  [RATIOS.MEDIUM]: {
    collectionMd: true
  },
  [RATIOS.LARGE]: {
    collectionLg: true
  }
};

// Collections
const defaultCollectionDataHandler = (data) => ({
  sectionHeading: data?.title,
  headerLink: data?.moduleData?.settings?.viewAll ? '/' : null,
  headerDescription: data?.description,
  separator: !!data?.moduleData?.settings?.separator,
  ...COLLECTIONS_ASPECT_RATIOS?.[data?.moduleData?.config?.aspectRatio],
  data: data?.moduleData?.config?.collections?.map(({ collection }) => ({
    id: collection?.id,
    title: collection?.title,
    coverImg: getImageUrl(collection?.thumbnail?.url) ?? '',
    blurHash: collection?.thumbnail?.blurhash,
    ...(data?.moduleData?.settings?.collectionNumberOfVideos && {
      videoCount: collection?.videosCount
    })
  }))
});
const getFeatureCollectionData = (collection) => ({
  id: collection?.id,
  title: collection.title ?? '',
  description: '',
  videoCount: collection?.videosCount,
  img: collection?.thumbnail?.url ?? '',
  blurHash: collection?.thumbnail?.blurhash,
  button: 'View Podcast'
});
const defaultFeatureCollectionDataHandler = (data, multiple = true) => ({
  ...(multiple
    ? {
        data: data?.moduleData?.config?.collections?.map(({ collection }) =>
          getFeatureCollectionData(collection)
        )
      }
    : getFeatureCollectionData(data.moduleData.config.collection))
});

// Speaker
const defaultAuthorDataHandler = (data) => ({
  sectionHeading: data?.title,
  headerLink: data?.moduleData?.settings?.viewAll ? '/' : null,
  separator: !!data?.moduleData?.settings?.separator,
  headerDescription: data?.description,
  data:
    data?.moduleData?.config?.authors?.map(({ author }) => ({
      id: author?.id,
      speaker: [author?.firstName, author?.lastName].filter(Boolean).join(' '),
      videoCount: author?.videosCount,
      url: getImageUrl(author?.image?.url) ?? '',
      blurHash: author?.image?.blurhash,
      description: author?.description
    })) ?? []
});

const defaultFeatureAuthorDataHandler = ({ author }) => ({
  speakerImage: author?.image?.url ?? '',
  blurHash: author?.image?.blurhash,
  speakerName: [author?.firstName, author?.lastName].filter(Boolean).join(' '),
  color: author?.primaryColor ?? 'bg-moss-2',
  speakerDescription:
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy"
});

// Topic
const defaultTopicDataHandler = (data) => ({
  sectionHeading: data?.title,
  headerLink: data?.moduleData?.settings?.viewAll ? '/' : null,
  headerDescription: data?.description,
  data:
    data?.moduleData?.config?.topics?.map(({ topic }) => ({
      id: topic?.id,
      topic: topic?.name ?? '',
      href: '/',
      backgroundColor: topic?.primaryColor ?? ''
    })) ?? []
});

const defaultFeatureTopicDataHandler = (data) => ({
  href: '/',
  topic: data?.moduleData?.featuredTopicConfig?.topic?.name ?? '',
  backgroundColor:
    data?.moduleData?.featuredTopicConfig?.topic?.primaryColor ?? ''
});

// Tag
const defaultTagDataHandler = (data) => ({
  sectionHeading: data?.title,
  headerDescription: data?.description,
  headerLink: data?.moduleData?.settings?.viewAll ? '/' : null,
  data:
    data?.moduleData?.config?.tags?.map(({ tag }) => ({
      id: tag?.key,
      name: tag?.name ?? ''
    })) ?? []
});

const defaultFeatureTagDataHandler = (data) => ({
  title: data?.moduleData?.config?.tag?.name
});

// CTA
const defaultCTADataHandler = (data) => ({
  img: data?.moduleData?.config?.asset?.url ?? '',
  title: data?.title ?? '',
  description: data?.description ?? '',
  background:
    THEME_PICKER_COLORS[data?.moduleData?.config?.theme]?.backgroundColor,
  contentlight: THEME_PICKER_COLORS[data?.moduleData?.config?.theme]?.textLight,
  primary: (
    <>
      {data?.moduleData?.config?.primaryAction?.asset?.url ? (
        <Avatar
          className="mr-8"
          src={data?.moduleData?.config?.primaryAction?.asset?.url}
          size={22}
        />
      ) : (
        ''
      )}
      {data?.moduleData?.config?.primaryAction?.title}
    </>
  ),
  secondary: (
    <>
      {data?.moduleData?.config?.secondaryAction?.asset?.url ? (
        <Avatar
          className="mr-8"
          src={data?.moduleData?.config?.secondaryAction?.asset?.url}
          size={22}
        />
      ) : (
        ''
      )}
      {data?.moduleData?.config?.secondaryAction?.title}
    </>
  ),
  blurHash: data?.moduleData?.config?.asset?.blurhash
});

const defaultCTAEventBlockDataHandler = (data) => ({
  ...defaultCTADataHandler(data),
  date: data?.moduleData?.config?.eventTime,
  location: data?.moduleData?.config?.eventLocation
});

const defaultInformationDataHandler = (data) => ({
  size: data?.moduleData?.config?.imageType,
  background:
    THEME_PICKER_COLORS[data?.moduleData?.config?.theme]?.backgroundColor,
  darkBackground:
    THEME_PICKER_COLORS[data?.moduleData?.config?.theme]?.textLight,
  imgSrc: data?.moduleData?.config?.asset?.url ?? '',
  title: data?.title,
  description: data?.description,
  primary: (
    <>
      {data?.moduleData?.config?.primaryAction?.asset?.url ? (
        <Avatar
          className="mr-8"
          src={data?.moduleData?.config?.primaryAction?.asset?.url}
          size={22}
        />
      ) : (
        ''
      )}
      {data?.moduleData?.config?.primaryAction?.title}
    </>
  ),
  secondary: (
    <>
      {data?.moduleData?.config?.secondaryAction?.asset?.url ? (
        <Avatar
          className="mr-8"
          src={data?.moduleData?.config?.secondaryAction?.asset?.url}
          size={22}
        />
      ) : (
        ''
      )}
      {data?.moduleData?.config?.secondaryAction?.title}
    </>
  )
});

const defaultCTABannerWithTextHandler = (data) => ({
  cta: data?.moduleData?.config?.primaryAction?.title,
  background:
    THEME_PICKER_COLORS[data?.moduleData?.config?.theme]?.backgroundColor,
  ctaBackgroundDark:
    THEME_PICKER_COLORS[data?.moduleData?.config?.theme]?.textLight,
  ctaBackgroundImage: data?.moduleData?.config?.asset?.url,
  ...(data?.moduleData?.config?.asset?.url && { ctaBackgroundDark: true }),
  title: data?.title ?? '',
  descriptions: data?.description ?? ''
});

const defaultDonationDataHandler = (data) => ({
  title: data?.title ?? '',
  description: data?.description ?? '',
  videoSectionTitle: data?.moduleData?.config?.secondaryTitle,
  videoSectionSubTitle: data?.moduleData?.config?.secondaryDescription,
  videoTitle: data?.moduleData?.config?.video?.title,
  sectionDark: THEME_PICKER_COLORS[data?.moduleData?.config?.theme]?.textLight,
  background:
    THEME_PICKER_COLORS[data?.moduleData?.config?.theme]?.backgroundColor
});

export const defaultCopyHeavyDataHandler = (data) => ({
  title: data?.title ?? '',
  authorImage: data?.moduleData?.config?.author?.image?.url,
  blurHash: data?.moduleData?.config?.author?.image?.blurhash,
  authorName: [
    data?.moduleData?.config?.author?.firstName,
    data?.moduleData?.config?.author?.lastName
  ]
    .filter(Boolean)
    .join(' '),
  authorSubTitle: data?.moduleData?.config?.author?.subTitle,
  date:
    data?.moduleData?.config?.date &&
    `Date ${moment(data?.moduleData?.config?.date).format('YY/MM/DD')}`,
  secondaryButton: data?.moduleData?.config?.secondaryAction?.title,
  primaryButton: data?.moduleData?.config?.primaryAction?.title,
  cmsContent: data?.moduleData?.config?.text,
  centerAlign: data?.moduleData?.config?.alignment === ALIGNMENTS.CENTER
});

export const defaultQuoteDataHandler = (data) => ({
  indicators: data?.moduleData?.settings?.indicators,
  data:
    data?.moduleData?.config?.quotes?.map(
      ({ text, author: { name, subTitle, asset } }, id) => ({
        id,
        authImg: asset?.url ?? '',
        quote: text,
        authName: name,
        authSubtitle: subTitle
      })
    ) ?? []
});

export const defaultHeroDataHandler = (data) => ({
  ...(data?.moduleData?.config?.asset?.categoryKey === ASSET_CATEGORY.VIDEO && {
    videoSrc: data?.moduleData?.config?.asset?.url
  }),
  ...(data?.moduleData?.config?.asset?.categoryKey === ASSET_CATEGORY.IMAGE && {
    imageSrc: data?.moduleData?.config?.asset?.url
  }),
  title: data?.title ?? '',
  description: data?.description ?? '',
  date: data?.moduleData?.config?.eventTime,
  location: data?.moduleData?.config?.eventLocation,
  cta1: (
    <>
      {data?.moduleData?.config?.primaryAction?.asset?.url ? (
        <Avatar
          className="mr-8"
          src={data?.moduleData?.config?.primaryAction?.asset?.url}
          size={22}
        />
      ) : (
        ''
      )}
      {data?.moduleData?.config?.primaryAction?.title}
    </>
  ),
  cta2: (
    <>
      {data?.moduleData?.config?.secondaryAction?.asset?.url ? (
        <Avatar
          className="mr-8"
          src={data?.moduleData?.config?.secondaryAction?.asset?.url}
          size={22}
        />
      ) : (
        ''
      )}
      {data?.moduleData?.config?.secondaryAction?.title}
    </>
  ),

  textAlignment: data?.moduleData?.config?.alignment
});

export const defaultLeadGenDataHandler = (data) => ({
  background:
    THEME_PICKER_COLORS[data?.moduleData?.config?.theme]?.backgroundColor,
  darkBackground:
    THEME_PICKER_COLORS[data?.moduleData?.config?.theme]?.textLight,
  title: data?.title,
  description: data?.description
});
export const defaultContactUsDataHandler = (data) => ({
  title: data?.title,
  subtitle: data?.description
});
export const defaultFAQDataHandler = (data) => ({
  title: data?.title,
  subTitle: data?.description,
  data:
    data?.moduleData?.config?.questions?.map(({ question, answer }, id) => ({
      id,
      heading: question,
      cmsContent: answer
    })) ?? []
});

export const defaultHtmlTextBlockDataHandler = (data) => ({
  title: {
    text: data?.moduleData?.config?.title?.text,
    alignment: data?.moduleData?.config?.title?.alignment,
    level: data?.moduleData?.config?.title?.size,
    toggles: data?.moduleData?.config?.title?.toggles
  },
  paragraphs: data?.moduleData?.config?.paragraphs ?? [],
  cta: data?.moduleData?.config?.cta
});

export const MODULES = {
  // Videos
  [MODULE_TYPES.VIDEO_CONTINUE_WATCHING_CAROUSEL]: (data) => ({
    id: data.id,
    type: MODULE_TYPES.VIDEO_CONTINUE_WATCHING_CAROUSEL,
    data: defaultVideoDataHandler(data),
    extraProps: {
      responsive: true
    }
  }),
  [MODULE_TYPES.VIDEO_WATCH_LATER_CAROUSEL]: (data) => ({
    id: data.id,
    type: MODULE_TYPES.VIDEO_WATCH_LATER_CAROUSEL,
    data: defaultVideoDataHandler(data),
    extraProps: {
      responsive: true
    }
  }),
  [MODULE_TYPES.VIDEO_CAROUSEL]: (data) => ({
    id: data.id,
    type: MODULE_TYPES.VIDEO_CAROUSEL,
    data: defaultVideoDataHandler(data),
    extraProps: {
      responsive: true
    }
  }),
  [MODULE_TYPES.VIDEO_LIST]: (data) => ({
    id: data.id,
    type: MODULE_TYPES.VIDEO_LIST,
    data: defaultVideoDataHandler(data),
    extraProps: {
      responsive: true
    }
  }),
  [MODULE_TYPES.VIDEO_GRID]: (data) => ({
    id: data.id,
    type: MODULE_TYPES.VIDEO_GRID,
    data: defaultVideoDataHandler(data),
    extraProps: {
      responsive: true
    }
  }),
  [MODULE_TYPES.FEATURED_VIDEO]: (data) => ({
    id: data.id,
    type: MODULE_TYPES.FEATURED_VIDEO,
    data: defaultFeatureVideoDataHandler(data, false),
    extraProps: {
      responsive: true,
      showCTA: data?.moduleData?.settings?.videoCTA
    }
  }),
  [MODULE_TYPES.FEATURED_VIDEO_SLIDER]: (data) => ({
    id: data.id,
    type: MODULE_TYPES.FEATURED_VIDEO_SLIDER,
    data: defaultFeatureVideoDataHandler(data),
    extraProps: {
      responsive: true,
      showIndicator: data?.moduleData?.settings?.carouselIndicator,
      showCTA: data?.moduleData?.settings?.videoCTA
    }
  }),
  [MODULE_TYPES.VIDEO_PLAYER]: (data) => ({
    id: data.id,
    type: MODULE_TYPES.VIDEO_PLAYER,
    data: defaultVideoPlayerDataHandler(data),
    extraProps: {
      responsive: true
    }
  }),
  [MODULE_TYPES.LIVE_VIDEO_PLAYER]: (data) => ({
    id: data.id,
    type: MODULE_TYPES.LIVE_VIDEO_PLAYER,
    data: defaultVideoPlayerDataHandler(data),
    extraProps: {
      responsive: true
    }
  }),
  // Collections
  [MODULE_TYPES.COLLECTION_CAROUSEL]: (data) => ({
    id: data.id,
    type: MODULE_TYPES.COLLECTION_CAROUSEL,
    data: defaultCollectionDataHandler(data),
    extraProps: {
      responsive: true
    }
  }),
  [MODULE_TYPES.COLLECTION_LIST]: (data) => ({
    id: data.id,
    type: MODULE_TYPES.COLLECTION_LIST,
    data: defaultCollectionDataHandler(data),
    extraProps: {
      responsive: true
    }
  }),
  [MODULE_TYPES.COLLECTION_GRID]: (data) => ({
    id: data.id,
    type: MODULE_TYPES.COLLECTION_GRID,
    data: defaultCollectionDataHandler(data),
    extraProps: {
      responsive: true
    }
  }),
  [MODULE_TYPES.FEATURED_COLLECTION]: (data) => ({
    id: data.id,
    type: MODULE_TYPES.FEATURED_COLLECTION,
    data: defaultFeatureCollectionDataHandler(data, false),
    extraProps: {
      responsive: true
    }
  }),
  [MODULE_TYPES.FEATURED_COLLECTION_SLIDER]: (data) => ({
    id: data.id,
    type: MODULE_TYPES.FEATURED_COLLECTION_SLIDER,
    data: defaultFeatureCollectionDataHandler(data),
    extraProps: {
      responsive: true
    }
  }),
  // Authors
  [MODULE_TYPES.AUTHOR_CAROUSEL]: (data) => ({
    id: data.id,
    type: MODULE_TYPES.AUTHOR_CAROUSEL,
    data: defaultAuthorDataHandler(data),
    extraProps: {
      responsive: true
    }
  }),
  [MODULE_TYPES.AUTHOR_LIST]: (data) => ({
    id: data.id,
    type: MODULE_TYPES.AUTHOR_LIST,
    data: defaultAuthorDataHandler(data),
    extraProps: {
      responsive: true
    }
  }),
  [MODULE_TYPES.AUTHOR_GRID]: (data) => ({
    id: data.id,
    type: MODULE_TYPES.AUTHOR_GRID,
    data: defaultAuthorDataHandler(data),
    extraProps: {
      responsive: true
    }
  }),
  [MODULE_TYPES.FEATURED_AUTHOR]: (data) => ({
    id: data.id,
    type: MODULE_TYPES.FEATURED_AUTHOR,
    data: defaultFeatureAuthorDataHandler(data.moduleData.config),
    extraProps: {
      responsive: true
    }
  }),
  // Topics
  [MODULE_TYPES.TOPIC_CAROUSEL]: (data) => ({
    id: data.id,
    type: MODULE_TYPES.TOPIC_CAROUSEL,
    data: defaultTopicDataHandler(data),
    extraProps: {
      responsive: true
    }
  }),
  [MODULE_TYPES.TOPIC_LIST]: (data) => ({
    id: data.id,
    type: MODULE_TYPES.TOPIC_LIST,
    data: defaultTopicDataHandler(data),
    extraProps: {
      responsive: true
    }
  }),
  [MODULE_TYPES.TOPIC_GRID]: (data) => ({
    id: data.id,
    type: MODULE_TYPES.TOPIC_GRID,
    data: defaultTopicDataHandler(data),
    extraProps: {
      responsive: true
    }
  }),
  [MODULE_TYPES.FEATURED_TOPIC]: (data) => ({
    id: data.id,
    type: MODULE_TYPES.FEATURED_TOPIC,
    data: defaultFeatureTopicDataHandler(data),
    extraProps: {
      responsive: true,
      lending: true
    }
  }),
  // Tag
  [MODULE_TYPES.TAG_CAROUSEL]: (data) => ({
    // eslint-disable-next-line react/destructuring-assignment
    id: data.id,
    type: MODULE_TYPES.TAG_CAROUSEL,
    data: defaultTagDataHandler(data),
    extraProps: {
      className: 'text-md tag-xs bg-n-400 b-0 text-n-900 mr-4',
      responsive: true,
      icon: <CaretRight size={16} />
    }
  }),
  [MODULE_TYPES.TAG_CLOUD]: (data) => ({
    // eslint-disable-next-line react/destructuring-assignment
    id: data.id,
    type: MODULE_TYPES.TAG_CLOUD,
    data: defaultTagDataHandler(data),
    extraProps: {
      className: 'text-md tag-xs bg-n-400 b-0 text-n-900 mr-4',
      responsive: true,
      icon: <CaretRight size={16} />
    }
  }),
  [MODULE_TYPES.FEATURED_TAG]: (data) => ({
    // eslint-disable-next-line react/destructuring-assignment
    id: data.id,
    type: MODULE_TYPES.FEATURED_TAG,
    data: defaultFeatureTagDataHandler(data),
    extraProps: {
      responsive: true
    }
  }),
  // CTA
  [MODULE_TYPES.CTA_BLOCK]: (data) => ({
    id: data.id,
    type: MODULE_TYPES.CTA_BLOCK,
    data: defaultCTADataHandler(data),
    extraProps: {
      responsive: true
    }
  }),
  [MODULE_TYPES.CTA_BANNER]: (data) => ({
    id: data.id,
    type: MODULE_TYPES.CTA_BANNER,
    data: { src: data?.moduleData?.config?.asset?.url },
    extraProps: {
      responsive: true
    }
  }),
  [MODULE_TYPES.CTA_EVENT_BLOCK]: (data) => ({
    id: data.id,
    type: MODULE_TYPES.CTA_EVENT_BLOCK,
    data: defaultCTAEventBlockDataHandler(data),
    extraProps: {
      responsive: true
    }
  }),
  [MODULE_TYPES.CTA_BANNER_WITH_TEXT]: (data) => ({
    id: data.id,
    type: MODULE_TYPES.CTA_BANNER_WITH_TEXT,
    data: defaultCTABannerWithTextHandler(data),
    extraProps: {
      responsive: true
    }
  }),
  [MODULE_TYPES.HERO]: (data) => ({
    id: data.id,
    type: MODULE_TYPES.HERO,
    data: defaultHeroDataHandler(data),
    extraProps: {
      responsive: true
    }
  }),
  // Donation
  [MODULE_TYPES.DONATION]: (data) => ({
    id: data.id,
    type: MODULE_TYPES.DONATION,
    data: defaultDonationDataHandler(data),
    extraProps: {
      responsive: true
    }
  }),
  // Copy Heavy
  [MODULE_TYPES.COPY_HEAVY]: (data) => ({
    id: data.id,
    type: MODULE_TYPES.COPY_HEAVY,
    data: defaultCopyHeavyDataHandler(data),
    extraProps: {
      responsive: true
    }
  }),
  [MODULE_TYPES.COPY_HEAVY_HERO]: (data) => ({
    id: data.id,
    type: MODULE_TYPES.COPY_HEAVY_HERO,
    data: {
      ...defaultCopyHeavyDataHandler(data),
      backgroundImage: data?.moduleData?.config?.asset?.url
    },
    extraProps: {
      responsive: true,
      heroModule: true
    }
  }),
  [MODULE_TYPES.QUOTE]: (data) => ({
    id: data.id,
    type: MODULE_TYPES.QUOTE,
    data: defaultQuoteDataHandler(data),
    extraProps: {
      responsive: true
    }
  }),
  [MODULE_TYPES.INFORMATION]: (data) => ({
    id: data.id,
    type: MODULE_TYPES.INFORMATION,
    data: defaultInformationDataHandler(data),
    extraProps: {
      responsive: true
    }
  }),
  [MODULE_TYPES.LEAD_GEN]: (data) => ({
    id: data.id,
    type: MODULE_TYPES.LEAD_GEN,
    data: defaultLeadGenDataHandler(data),
    extraProps: {
      responsive: true
    }
  }),
  [MODULE_TYPES.CONTACT_US]: (data) => ({
    id: data.id,
    type: MODULE_TYPES.CONTACT_US,
    data: defaultContactUsDataHandler(data),
    extraProps: {
      responsive: true
    }
  }),
  [MODULE_TYPES.FAQ]: (data) => ({
    id: data.id,
    type: MODULE_TYPES.FAQ,
    data: defaultFAQDataHandler(data),
    extraProps: {
      responsive: true
    }
  }),
  [MODULE_TYPES.SPACER]: (data) => ({
    id: data.id,
    type: MODULE_TYPES.SPACER,
    data: {
      className: data?.moduleData?.config?.mobile
    }
  }),
  [MODULE_TYPES.HTML_TEXT_BLOCK]: (data) => ({
    id: data.id,
    type: MODULE_TYPES.HTML_TEXT_BLOCK,
    data: defaultHtmlTextBlockDataHandler(data),
    extraProps: {
      responsive: true
    }
  })
};

const PreviewPageProvider = ({ children }) => {
  const { id } = useParams();
  const [modules, setModules] = useState([]);

  const {
    loading,
    refetch,
    data: { pageAdmin: { slug, type } = {} } = {}
  } = useQuery(GET_PAGE_DETAILS_FOR_PREVIEW, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ pageAdmin }) => {
      if (!pageAdmin) return;
      const { modules: data = [] } = pageAdmin;

      const newModules = data
        .map((module) => MODULES[module.type]?.(module))
        .filter(Boolean);

      setModules(newModules);
    },
    variables: { id }
  });

  return (
    <ctx.Provider value={{ modules, loading, refetch, slug, type }}>
      {children}
    </ctx.Provider>
  );
};

export default PreviewPageProvider;
