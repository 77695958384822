import { useMutation } from '@apollo/client';
import { Button, Form, Space } from 'antd';
import React, { useEffect } from 'react';
import { MODULE_TYPES, STATUS_TYPES } from '../../../../../common/constants';
import { FORM_TYPES } from '../../../context/EditPageProvider';
import {
  CREATE_PAGE_MODULE,
  UPDATE_PAGE_MODULE
} from '../../../graphql/Mutations';
import { ModuleFields, ShowFields } from './FormInputs';

const initialValues = {
  title: '',
  description: '',
  status: STATUS_TYPES.PUBLISHED,
  permissions: [],
  settings: {
    title: true
  },
  config: {}
};

const SETTINGS = [
  {
    name: 'title',
    label: 'Title',
    allowedTypes: [MODULE_TYPES.GOAL, MODULE_TYPES.MAP]
  }
];

const MODULE_KEYS = {
  [MODULE_TYPES.GOAL]: 'goalModule',
  [MODULE_TYPES.MAP]: 'mapModule'
};

function OtherForm({
  form: { type: formType, moduleId, defaultValues, index: order },
  pageId,
  type,
  onCancel,
  onSuccess,
  onSettingsChange
}) {
  const [form] = Form.useForm();
  const isEdit = formType === FORM_TYPES.EDIT;

  const settingsProps = Form.useWatch(['settings'], form);

  useEffect(() => {
    if (settingsProps) {
      onSettingsChange(settingsProps);
    }
  }, [settingsProps]);

  useEffect(() => {
    if (moduleId && defaultValues && formType === FORM_TYPES.EDIT && form) {
      form.setFieldsValue({
        title: defaultValues?.title ?? '',
        description: defaultValues?.description ?? '',
        status: defaultValues?.status ?? STATUS_TYPES.DRAFT,
        permissions:
          defaultValues?.permissions?.map((value) => ({
            label: value,
            value
          })) ?? [],
        settings: {
          ...initialValues.settings,
          ...defaultValues?.moduleData?.settings
        }
      });
    }
  }, [form, moduleId, formType, defaultValues, form]);

  const [addEditModule, { loading }] = useMutation(
    isEdit ? UPDATE_PAGE_MODULE : CREATE_PAGE_MODULE
  );

  const handleSubmit = ({ settings, permissions, ...rest }) => {
    const key = MODULE_KEYS[type];

    const payload = {
      ...(!isEdit && {
        type,
        order: order + 1
      }),
      permissions: permissions?.map(({ value }) => value),
      ...rest,
      ...(key && {
        [key]: {
          settings
        }
      })
    };

    addEditModule({
      variables: { data: payload, id: isEdit ? moduleId : pageId }
    }).then(
      ({
        data: {
          addUpdatedPageModule: { pageModule }
        }
      }) => {
        onSuccess(pageModule);
      }
    );
  };

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={handleSubmit}
      initialValues={initialValues}
    >
      <ModuleFields showDescription={![MODULE_TYPES.GOAL].includes(type)} />
      {MODULE_TYPES.DIVIDER !== type && (
        <Space className="w-full" direction="vertical">
          <ShowFields settings={SETTINGS} type={type} />
        </Space>
      )}
      <div className="d-flex button-section">
        <Space>
          <Form.Item>
            <Button
              type="text"
              htmlType="submit"
              className="text-btn mr-8"
              size="middle"
              disabled={loading}
              loading={loading}
            >
              Save
            </Button>
          </Form.Item>

          <Form.Item>
            <Button
              disabled={loading}
              onClick={onCancel}
              type="text"
              className="text-btn2"
            >
              Cancel
            </Button>
          </Form.Item>
        </Space>
      </div>
    </Form>
  );
}

export default OtherForm;
