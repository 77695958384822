import { Button } from 'antd';
import React from 'react';
import Image from '../../../components/Image';

function FeaturedCollection({
  title,
  img,
  blurHash,
  videoCount,
  background = 'bg-n-900',
  description,
  buttonStyle = 'text-n-900 bg-n-0 hbg-n-900 hc-n-100 hbc-n-100',
  button,
  titleStyle = 'text-n-100',
  countStyle = 'text-white-70',
  descriptionStyle = 'text-n-100',
  responsive
}) {
  return (
    <section
      className={`featuredcollection ${responsive ? 'responsive-view' : ''}`}
    >
      <div className="container-rnp">
        <div className={`featuredcollection-wrapper ${background}`}>
          {img && (
            <div className="featuredcollection-img relative">
              <Image blurHash={blurHash} src={img} alt="images" />
            </div>
          )}

          <div className="featuredcollection-dec">
            {title && <div className={`title ${titleStyle}`}>{title}</div>}
            {videoCount !== undefined ? (
              <div className={`featuredcollection-video-count ${countStyle}`}>
                {videoCount} Videos
              </div>
            ) : null}

            {description && <p className={descriptionStyle}>{description}</p>}

            <Button size="large" className={`cs-button ${buttonStyle}`}>
              {button}
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
}
export default FeaturedCollection;
