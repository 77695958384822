import { Tag } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

function TagCard({ className, name, icon, href = '#' }) {
  return (
    <div>
      <Link to={href}>
        <Tag className={className} icon={icon}>
          {name}
        </Tag>
      </Link>
    </div>
  );
}
export default TagCard;
