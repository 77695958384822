import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../../common/constants';
import AddEditIcon from './AddEditIcon';
import Icons from './Icons';

const IconsWrapper = () => {
  return (
    <Switch>
      <Route path={ROUTES?.ICONS} exact component={Icons} />
      <Route path={`${ROUTES?.ICONS}/add`} component={AddEditIcon} />
      <Route path={`${ROUTES?.ICONS}/:iconId/edit`} component={AddEditIcon} />
      <Route
        exact
        path={`${ROUTES?.ICONS}/:id`}
        render={() => <Redirect to={ROUTES?.ICONS} />}
      />
    </Switch>
  );
};

export default IconsWrapper;
