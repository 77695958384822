import { Input } from 'antd';
import React from 'react';
import { COLOUR_CODES } from '../../../../common/constants';

export const ColorPicker = ({ onChange, value }) => {
  return (
    <div className="d-flex">
      {COLOUR_CODES?.map(({ color }) => (
        <div
          key={color}
          className={`color-list ${color} ${
            value === color ? 'active-color' : ''
          }`}
          onClick={() => onChange(color)}
        />
      ))}
    </div>
  );
};

export const SlugInput = ({
  value,
  onChange,
  placeholder = 'Enter Slug',
  ...rest
}) => {
  return (
    <Input
      placeholder={placeholder}
      value={value}
      onChange={(e) => {
        const val = e.target.value;
        const newSlugValue = val.startsWith('/') ? val : `/${val}`;
        onChange(newSlugValue);
      }}
      {...rest}
    />
  );
};
