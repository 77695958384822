import React from 'react';
import Image from '../../../components/Image';

function FeaturedSpeaker({
  speakerName,
  speakerDescription,
  speakerImage,
  blurHash,
  responsive
}) {
  return (
    <section
      className={`featurdspeaker ${responsive ? 'responsive-view' : ''}`}
    >
      <div className="container-rnp">
        <div className="featurdspeaker-wrapper">
          <div className="featurdspeaker-image">
            {speakerImage ? (
              <Image blurHash={blurHash} src={speakerImage} alt={speakerName} />
            ) : null}
          </div>

          <div className="featurdspeaker-detail">
            <div className="featurdspeaker-detail-wrapper">
              {speakerName ? (
                <div className="featurespeaker-name">{speakerName}</div>
              ) : null}
              {speakerDescription ? <p>{speakerDescription}</p> : null}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default FeaturedSpeaker;
