import React from 'react';
import { MODULES } from '../../common/constants';
import PageHeader from '../../components/PageHeader';
import LayoutRow from '../component/layout/LayoutRow';
import LeftPanel from './component/pageModules/LeftPanel';
import Preview from './component/pageModules/Preview';
import RightPanel from './component/pageModules/RightPanel';
import EditPageProvider from './context/EditPageProvider';
import PreviewPageProvider from './context/PreviewPageProvider';

const EditPage = () => {
  return (
    <>
      <PageHeader menu={MODULES?.PAGES} />
      <div className="page-wrapper">
        <div className="page-wrapper-body edit-page">
          <LayoutRow>
            <LeftPanel />
            <RightPanel />
            <Preview />
          </LayoutRow>
        </div>
      </div>
    </>
  );
};

const wrapper = () => {
  return (
    <PreviewPageProvider>
      <EditPageProvider>
        <EditPage />
      </EditPageProvider>
    </PreviewPageProvider>
  );
};

export default wrapper;
