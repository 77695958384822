import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../../common/constants';
import AddEditText from './AddEditText';
import Text from './Text';

const TextWrapper = () => (
  <Switch>
    <Route path={ROUTES?.TEXT} exact component={Text} />
    <Route path={`${ROUTES?.TEXT}/add`} component={AddEditText} />
    <Route path={`${ROUTES?.TEXT}/:id/edit`} component={AddEditText} />
    <Route
      exact
      path={`${ROUTES?.TEXT}/:id`}
      render={() => <Redirect to={ROUTES?.TEXT} />}
    />
  </Switch>
);

export default TextWrapper;
